import React, {FC, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import callnxtlogo from "../../images/pegionlogo.png";
import styles from "./RechargeScreen.module.css";
import {initiatePayment} from "../../api/endpoint";
import {notyf} from "../../notyf";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";
import {UserDetailsContext} from "../../contextApi/UserDetailsContext/UserDetailsContext";
import {PaymentType} from "../../domains/PaymentType";
import {useNavigate} from "react-router-dom";
import {CartContext} from "../../contextApi/CartContext/CartContext";

const RechargeScreen: FC = () => {
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gatewayFee, setGatewayFee] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const {accessToken} = useContext(AuthContext);
  const {user} = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const {addProduct, clearCart} = useContext(CartContext);
  const amountRegex = /^\d{0,5}(\.\d{0,2})?$/;

  useEffect(() => {
    if (amount > 0) {
      const gatewayFeeAmount = (amount * 0.05);
      setGatewayFee(gatewayFeeAmount);
      setTotalAmount(amount + gatewayFeeAmount);
    } else {
      setGatewayFee(0);
      setTotalAmount(0);
    }
  }, [amount]);

  const processPayment = () => {
    if (amount < 30) {
      notyf.error("Minimum amount should be $30.");
      return;
    }
    setIsLoading(true);
    clearCart();
    initiatePayment(accessToken ? accessToken : "", amount, PaymentType.RECHARGE, "")
      .then((response) => {
        try {
          addProduct(
            [{
              name: `Ad-Hoc Recharge (${user?.account})`,
              price: amount,
              quantity: 1
            }]
          )
          navigate(`/checkout?paymentIntentId=${response.id}&clientSecret=${response.clientSecret}&currency=${response.currency}`);
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to initiate payment.");
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.outerContainer} data-testid="recharge">
      <div className={styles.rechargeContainer}>
        <div className={styles.topBar}>
          <img src={callnxtlogo} alt="logo" className={styles.logoImage}/>
        </div>

        <div className={styles.rechargeHeading}>
          <h2>Recharge Amount</h2>

          <p className={styles.rechargePara}>
            We will be processing the same amount
            <br/>
            and you will be notified via email
          </p>
        </div>

        <div className={styles.formFieldContainer}>
          <div className={styles.row}>
            <div className={styles.label}>Currency</div>
            <div className={styles.usd}>: USD</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Amount</div>
            <div className={styles.amountFieldContainer}>
              <input
                className={styles.amountField}
                type="number"
                value={amount == 0 ? "" : amount}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if(amountRegex.test(inputValue)) {
                    setAmount(parseFloat(inputValue) || 0)
                  }
                }}
                placeholder=" $ 00.00"
                data-testid={"amount-field"}
              />
            </div>
          </div>

          {amount > 0 && (
            <div className={styles.feeTotalContainer}>
              <div className={styles.feeRow}>
                <div className={styles.feeLabel}>Payment Gateway Fee (5%)</div>
                <div className={styles.feeAmount}>: ${gatewayFee.toFixed(2)}</div>
              </div>
              <div className={styles.totalRow}>
                <div className={styles.totalLabel}>Total Amount</div>
                <div className={styles.totalAmount}>: ${totalAmount.toFixed(2)}</div>
              </div>
            </div>
          )}
        </div>
        {!isLoading && (
          <SkyButton
            text={"Pay"}
            size={ButtonSize.SMALL}
            onClick={processPayment}
            disabled={!amount || (user?.status !== "ACTIVE")}
          />
        )}
        {isLoading && <DotLoader/>}
      </div>
    </div>
  );
};

export default RechargeScreen;
