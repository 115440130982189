import React from 'react'
import {useNavigate} from "react-router-dom";
import styles from "./NotFoundScreen.module.css";
import notFoundImage from "../../images/not-found.png";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";

const NotFoundScreen = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapperContainer}>
        <img
          src={notFoundImage}
          alt="not-found-image"
          className={styles.image}
        />
        <div className={styles.textContainer}>
          <h1 className={styles.title}>
            The page you're looking for doesn't exist
          </h1>
          <div className={styles.buttonContainer}>
            <SkyButton
              size={ButtonSize.SMALL}
              onClick={handleClick}
              text={"Go to Dashboard"}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundScreen