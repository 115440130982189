import React from 'react'
import {useNavigate} from "react-router-dom";
import styles from "./SignUpScreen.module.css";
import logo from "../../images/logo.png";
import SignUp from "./SignUp/SignUp";

const SignUpScreen = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.signUpContainer}>
      <div className={styles.container}>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
        />
        <div className={styles.loginFooterText}>
          Register to discover a new journey
        </div>
      </div>
      <div className={styles.signUpWrapperContainer}>
        <SignUp/>
      </div>
      <div className={styles.loginFooter}>
        <div className={styles.loginPromptText}>
          Already have an account?
        </div>
        <button
          onClick={() => navigate("/login")}
          className={styles.loginText}
        >
          Login Now
        </button>
      </div>
    </div>
  )
}
export default SignUpScreen
