import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import paymentFailed from "../../../images/rechargeFailed.png";
import styles from "./RechargeFailure.module.css";
import SkyButton, {ButtonSize} from "../../../components/base/SkyButton/SkyButton";
import {PaymentType} from "../../../domains/PaymentType";

export type Props = {
  isBalanceUpdated: boolean;
  gatewayStatus: string;
  errorCode: string;
  paymentType: PaymentType;
};

const RechargeFailure: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const redirectToPaymentPage = () => {
    navigate(props.paymentType === PaymentType.RECHARGE ? "/recharge" : "/profile?tab=invoice");
  };

  const renderHeader = () => {
    return props.paymentType === PaymentType.RECHARGE ? 'Recharge Failed' : 'Invoice Payment Failed';
  };

  const renderMessage = () => {
    if (props.paymentType === PaymentType.RECHARGE) {
      return "Your recharge payment has failed";
    } else {
      return "Your invoice payment has failed";
    }
  };

  const renderRetryMessage = () => {
    if (props.gatewayStatus !== "SUCCEEDED") {
      return props.paymentType === PaymentType.RECHARGE
        ? "You can retry the recharge payment below to continue."
        : "You can retry the invoice payment below to continue.";
    } else {
      return "Please contact support@skytelservices.com before retrying the payment again.";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.roundWhiteContainer}>
        <p className={styles.headerText}>{renderHeader()}</p>
        <img
          alt="payment failed"
          className={styles.failureImage}
          src={paymentFailed}
        />
        <div className={styles.bottomContainer}>
          <p className={styles.messageHeader}>{renderMessage()}</p>
          <p className={styles.messageSubHeader}>
            Gateway Status Code:{" "}
            {props.gatewayStatus != "" ? props.gatewayStatus : "UNKNOWN"}
          </p>
          <p className={styles.messageSubHeader}>
            Error Code: {" "}
            {props.errorCode !== "" ? props.errorCode : "UNKNOWN"}
          </p>
          <p className={styles.messageText}>
            {renderRetryMessage()}
          </p>
        </div>
        {props.gatewayStatus != "SUCCEEDED" && (
          <>
            <SkyButton
              text="Retry"
              size={ButtonSize.SMALL}
              onClick={redirectToPaymentPage}
            />
            <p className={styles.messageText}>
              Please contact support@skytelservices.com for any assistance.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default RechargeFailure;
