import React, { FC, useEffect, useState } from "react";
import styles from "../DetailForm.module.css";
import UploadForm from "../UploadForm/UploadForm";
import error from "../../../../icons/error-emoji.png";
import { documentToBase64, PersonalDetails } from "../../../../domains/KYC";
import SkyInputBox from "../../../base/SkyInputBox/SkyInputBox";
import DocumentUploader from "../DocumentUploader/DocumentUploader";

type IdentityProofProps = {
  kycData: PersonalDetails;
  onChange: (details: PersonalDetails) => void;
};

const PersonalDetailsForm: FC<IdentityProofProps> = (props) => {
  const [attnName, setAttnName] = useState<string>(
    props.kycData.attnName
  );
  const [email, setEmail] = useState<string>(
    props.kycData.email
  );
  const [chatId, setChatId] = useState<string>(
    props.kycData.chatId
  );
  const [directNo, setDirectNo] = useState<string>(
    props.kycData.directNo
  );
  const [supportingDocument, setSupportingDocument] = useState<string>(
    props.kycData.supportingDocument
  );
  const [isOpenUploadOption, setIsOpenUploadOption] = useState<boolean>(false);
  const [errorMessage] = useState<string | null>("");

  useEffect(() => {
    props.onChange({
      attnName: attnName,
      email: email,
      chatId: chatId,
      directNo: directNo,
      supportingDocument: supportingDocument
    });
  }, [attnName, email, chatId, directNo, supportingDocument]);

  return (
    <div className={styles.formContainer} data-testid="identity-details-form">
      {isOpenUploadOption ? (
        <UploadForm
          onClose={()=>{}}
          onFileSelect={()=>{}}
        />
      ) : (
        <div className={styles.wrapperContainer}>
          <div className={styles.leftContainer}>
            <DocumentUploader
              handleUploadDoc={()=>{}}
              supportingDocument={supportingDocument}
              disabled={true}
              isValid={true}
            />
          </div>
          <div className={styles.rightContainer}>
            <label>
              Required for Personal purpose
              <br />
              (Optional)
            </label>
            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setAttnName(value as string)}
                placeholder={"Attn. Name"}
                type={"text"}
                value={attnName}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setEmail(value as string)}
                placeholder={"Email ID"}
                type={"text"}
                value={email}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setChatId(value as string)}
                placeholder={"Chat ID"}
                type={"text"}
                value={chatId}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setDirectNo(value as string)}
                placeholder={"Direct Number"}
                type={"text"}
                value={directNo}
              />
            </div>

            {errorMessage && (
              <div className={styles.error}>
                <img src={error} alt="error-icon" />
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default PersonalDetailsForm;
