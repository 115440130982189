import React, { FC } from "react";
import styles from "./footercomponent.module.css";

type Props = {};

const Footer: FC<Props> = () => {
  const redirectTo = (routeName: string) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/${routeName}`;
  };

  return (
    <p className={styles.copyright} data-testid="footer">
      Copyright &copy; All Right reserved by Skytel
      <a
        className={styles.clickable}
        href={redirectTo("privacy-policy")}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>, {" "}
      <a
        className={styles.clickable}
        href={redirectTo("term-of-use")}
        target="_blank"
        rel="noreferrer"
      >
        Term of Use
      </a>
    </p>
  );
};

export default Footer;
