import React, {FC, useContext, useEffect, useState} from "react";
import styles from "./BaseScreen.module.css";
import Footer from "../../components/business/Footer/footercomponent";
import MenuBar from "../../components/business/MenuBar/menuBar";
import Header from "../../components/business/Header/headerComponent";
import {Navigate, Outlet} from "react-router-dom";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import classNames from "classnames";
import ProfileDropdownCard from "../../components/business/ProfileDropdownCard/profileDropdownCard";
import {acceptTermsAndConditions, fetchTermsAndConditions, fetchUserProfile} from "../../api/endpoint";
import {UserDetailsResponse} from "../../models/response/UserDetaiilsResponse";
import {bottomMenuList, topMenuList} from "../../domains/Menu";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {FeatureToggleContext} from "../../contextApi/FeatureToggleContext/FeatureToggleContext";
import Dialog, {Size} from "../../components/business/Dialog/Dialog";
import MigrateUserForm from "../../components/business/MigrateUserForm/MigrateUserForm";
import {UserDetailsContext} from "../../contextApi/UserDetailsContext/UserDetailsContext";
import KycForm from "../../components/business/KycForm/KycForm";
import KYCBanner from "./banners/KYCBanner";
import DevBanner from "./banners/DevBanner";
import {ViewPortSizeContext} from "../../contextApi/ViewportSizeContext/ViewPortSizeContext";
import TermsAndConditionsDialog from "../../components/business/TermsAndConditions/TermsAndConditionsDialog";
import {TermsAndConditions} from "../../models/response/FetchTermsAndConditions";
import {notyf} from "../../notyf";

const BaseScreen: FC = () => {
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [isMenuBarExpanded, setIsMenuBarExpanded] = useState(false);
  const [clientName, setClientName] = useState<string>("");
  const [emailId, setEmailId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isKycDialogOpen, setIsKycDialogOpen] = useState<boolean>(false);
  const {accessToken} = useContext(AuthContext);
  const {isProdEnv} = useContext(FeatureToggleContext);
  const {isNewLoginFlow} = useContext(FeatureToggleContext);
  const {user} = useContext(UserDetailsContext);
  const {setIsProfileCardExpanded} = useContext(ViewPortSizeContext);
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditions[]>([]);

  const handleArrowClick = (showProfileCard: boolean) => {
    setShowProfileCard(showProfileCard);
    setIsProfileCardExpanded(showProfileCard);
  };

  const onToggle = (isMenuBarExpanded: boolean) => {
    setIsMenuBarExpanded(isMenuBarExpanded);
  };

  const getUserDetails = () => {
    if (accessToken) {
      fetchUserProfile(accessToken).then(
        (userDetailsResponse: UserDetailsResponse) => {
          if (userDetailsResponse != undefined) {
            setClientName(userDetailsResponse.companyName);
            setEmailId(userDetailsResponse.email);
          }
        }
      );
    }
  };

  const getTermsAndConditions = () => {
    if (accessToken) {
      fetchTermsAndConditions(accessToken).then(
        (termsList: TermsAndConditions[]) => {
          setTermsAndConditions(termsList);
        }
      );
    }
  };

  useEffect(() => {
    getUserDetails();
    if (user && user?.status !== "NOT_AVAILABLE") {
      getTermsAndConditions();
    }
  }, [accessToken, user]);

  useEffect(() => {
    setIsDialogOpen(isNewLoginFlow && user?.status == "NOT_AVAILABLE");
  }, [isNewLoginFlow, user]);

  const handleTermsAndConditionsAccept = async () => {
    if (accessToken && termsAndConditions.length > 0) {
      const termIds = termsAndConditions.map((term) => term.id);
      await acceptTermsAndConditions(accessToken, termIds)
        .then(() => {
          notyf.success('Terms and conditions accepted successfully.');
          getTermsAndConditions();
        })
        .catch(() => {
          notyf.error('Error accepting terms and conditions');
        })
    }
  };

  return (
    <>
      {accessToken ? (
        <>
          <div className={styles.parentContainer}>
            {(user?.status === "KYC_PENDING" ||
              user?.status === "KYC_REJECTED" ||
              user?.status === "KYC_SUBMITTED"
            ) && (
              <KYCBanner
                clientName={clientName}
                onClick={() => setIsKycDialogOpen(true)}
                isNonProd={!isProdEnv}
                userStatus={user?.status}
                rejectionReason={user?.kycRejectionReason}
              />
            )}
            {!isProdEnv &&
              (user?.status !== "KYC_PENDING" &&
                user?.status !== "KYC_SUBMITTED" &&
                user?.status !== "KYC_REJECTED") &&
               <DevBanner/>
            }
            {isDialogOpen && (
              <Dialog
                size={Size.SM}
                header={"Update Login Credentials"}
                renderer={() => (
                  <MigrateUserForm onClose={() => setIsDialogOpen(false)}/>
                )}
                testId={`baseScreen-dialog-migrate`}
              />
            )}
            {termsAndConditions.length > 0 && (
              <Dialog
                testId="terms-conditions-dialog"
                size={Size.XL}
                header="Terms and Conditions"
                renderer={() => (
                  <TermsAndConditionsDialog
                    termsAndConditions={termsAndConditions}
                    onConfirm={handleTermsAndConditionsAccept}
                  />
                )}
              />
            )}
            {isKycDialogOpen && (
              <Dialog
                size={Size.SM}
                defaultBackgroundColor="white"
                renderer={() => (
                  <KycForm onClose={() => setIsKycDialogOpen(false)}/>
                )}
                testId={`baseScreen-kyc-form`}
              />
            )}
            <div
              className={classNames(
                styles.childContainer,
                styles.menuBarContainer,
                isMenuBarExpanded ? styles.expand : ""
              )}
            >
              <MenuBar
                topMenuItems={topMenuList}
                bottomMenuItems={bottomMenuList}
                onToggle={onToggle}
              />
            </div>
            <div
              className={classNames(
                styles.childContainer,
                styles.contentContainer
              )}
            >
              <Header
                userName={clientName}
                handleArrowClick={handleArrowClick}
              />
              <div className={styles.nestedChildContainer}>
                <div
                  className={classNames(
                    styles.outletContainer,
                    isMenuBarExpanded ? styles.shrink : ""
                  )}
                  data-testid="content-outlet"
                >
                  <Outlet/>
                </div>

                <div
                  className={classNames(
                    styles.profileCardContainer,
                    !showProfileCard ? styles.hide : ""
                  )}
                >
                  <ProfileDropdownCard
                    userName={clientName}
                    emailId={emailId}
                    handleProfileClick={handleArrowClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer/>
          <ToastContainer/>
        </>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
};

export default BaseScreen;