export interface NumberRedirection {
    id: string;
    virtualNumber: string;
    type: string;
    lastUpdated: string;
}

export interface Registration {
    id: number;
    clientAgent: string;
    registrationIpAddress: string;
    updatedAt: string;
}

export interface GetExtensionResponse {
    id: string;
    username: string;
    sipHost: string;
    status: ExtensionStatus;
    createdAt: string;
    updatedAt: string;
    numberRedirections: NumberRedirection[];
    registrations: Registration[];
}

export enum ExtensionStatus {
    UNPROVISIONED = 'UNPROVISIONED',
    CONFIGURED = 'CONFIGURED',
    AGENT_REGISTERED = 'AGENT_REGISTERED'
}
