import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./Login.module.css";
import {resetPassword} from "../../../api/endpoint";
import {isOTPRequired} from "../../../requester";
import {notyf} from "../../../notyf";
import {OTPContext} from "../../../contextApi/OTPContext/OTPContext";
import ResetPasswordForm, {
  ResetPasswordFormData
} from "../../../components/business/ResetPasswordForm/ResetPasswordForm";
import Dialog from "../../../components/business/Dialog/Dialog";
import LoginForm from "./LoginFom/LoginFom";

const Login: FunctionComponent = () => {
  const [showResetPasswordDialog, setShowResetPasswordDialog] =
    useState<boolean>(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] =
    useState<boolean>(false);
  const [isResetPasswordLoading, setIsResetPasswordLoading] =
    useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
  } = useContext(OTPContext);

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      handleResetPasswordSuccess();
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);
  const handleResetPasswordSuccess = () => {
    setShowResetPasswordDialog(false);
    setIsPasswordResetSuccess(true);
    setDialogMessage("Password Updated Successfully");
  };

  const handleResetPassword = (formData: ResetPasswordFormData) => {
    setIsResetPasswordLoading(true)
    resetPassword({
      username: formData.username,
      password: formData.password,
    })
      .then((_) => {
        handleResetPasswordSuccess();
      })
      .catch((error) => {
        if (isOTPRequired(error)) {
          setOTPRequired(true);
          setErrorResponse(error);
        } else {
          notyf.error("Something went wrong. Please try again later");
        }
      }).finally(() => {
      setIsResetPasswordLoading(false)
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.formOuterContainer}>
        <div className={styles.formInnerContainer}>
          <div className={styles.formContainer}>
            <LoginForm
              onErrorResponse={(message: string) => {
                setDialogMessage(message);
              }}
              onResetPassword={() => {
                setIsPasswordResetSuccess(false);
                setShowResetPasswordDialog(true);
              }}
            />
          </div>
        </div>
      </div>

      {dialogMessage.trim().length > 0 && (
        <Dialog
          header={"Alert"}
          message={dialogMessage}
          onConfirm={() => setDialogMessage("")}
        />
      )}

      {showResetPasswordDialog && (
        <div className={styles.resetContainer}>
          <Dialog
            header={"Reset Password"}
            renderer={() => (
              <ResetPasswordForm
                onCancel={() => setShowResetPasswordDialog(false)}
                onConfirm={(formData) => handleResetPassword(formData)}
                isPasswordResetSuccess={isPasswordResetSuccess}
                isLoading={isResetPasswordLoading}
              />
            )}
          />
         </div>
      )}
    </div>
  );
};

export default Login;