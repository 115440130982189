import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import styles from "./LoginFom.module.css";
import {useNavigate} from "react-router-dom";
import {authenticateUser} from "../../../../api/endpoint";
import {UserLoginRequest} from "../../../../models/request/UserLoginRequest";
import {AuthContext} from "../../../../contextApi/AuthContext/authContext";
import {UserLoginResponse} from "../../../../models/response/UserLoginResponse";
import cookie from "react-cookies";
import loaderClip from "../../../../images/transparent loader.svg";
import errorIcon from "../../../../icons/error-emoji.png";
import classNames from "classnames";
import {SkyInputField} from "../../../../components/base/SkyForm/skyForm";

type Props = {
  onSubmit?: (loginRequest: UserLoginRequest) => void;
  onErrorResponse: (message: string) => void;
  onResetPassword: () => void;
};

enum LoginFromLevel {
  USERNAME_INPUT = "USERNAME_INPUT",
  PASSWORD_INPUT = "PASSWORD_INPUT",
}

type FormField = {
  data: string,
  isError: boolean
}

type FormFields = {
  username: FormField;
  password: FormField;
}

const LoginForm: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const initialFormFieldData = {
    username: {data: "", isError: false},
    password: {data: "", isError: false},
  };

  const [form, setForm]
    = useState<FormFields>(initialFormFieldData);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitButtonText, setSubmitButtonText] = useState<string>("Next");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const usernameField: SkyInputField = {
    label: "Email / Username",
    type: "text",
    id: "username",
    name: "username",
    placeholder: "username / email",
    onchange: (e) => {
      setForm({
        ...form,
        username: {...form.username, data: e.target.value}
      });
    },
  };
  const passwordField: SkyInputField = {
    label: "Password",
    type: "password",
    id: "password",
    name: "password",
    placeholder: "********",
    onchange: (e) => {
      setForm({
        ...form,
        password: {...form.password, data: e.target.value}
      });
    }
  };
  const [formFields, setFormFields] = useState<SkyInputField[]>([
    usernameField,
  ]);
  const [formLevel, setFormLevel] = useState<LoginFromLevel>(
    LoginFromLevel.USERNAME_INPUT
  );

  const {setAccessToken, removeAccessToken} = useContext(AuthContext);

  const handleUsernameInput = () => {
    if (form.username.data.trim() === "") {
      setForm({
        ...form,
        username: {...form.username, isError: true},
      });
      setErrorMessage("Email/Username should not be blank");
      return;
    }
    setFormLevel(LoginFromLevel.PASSWORD_INPUT);
    setFormFields([usernameField, passwordField]);
    setSubmitButtonText("Login");
  };

  const handlePasswordInput = () => {
    if (form.password.data.trim() === "") {
      setForm({
        ...form,
        password: {...form.password, isError: true},
      });
      setErrorMessage("Password should not be blank");
      return;
    }
    login();
  };

  const handleSubmit = () => {
    setErrorMessage("");
    if (formLevel === LoginFromLevel.USERNAME_INPUT) {
      handleUsernameInput();
    } else {
      handlePasswordInput();
    }
  };

  const login = () => {
    const loginRequest: UserLoginRequest = {
      username: form.username.data,
      password: form.password.data,
    };

    setIsLoading(true);
    authenticateUser(loginRequest)
      .then((loginResponse: UserLoginResponse) => {
        try {
          if (loginResponse.accessToken) {
            setAccessToken(loginResponse.accessToken);
            const cookieOptions = {
              path: "/",
              expires: new Date(Date.now() + 60 * 60 * 1000),
            };
            cookie.save(
              "accessToken",
              loginResponse.accessToken,
              cookieOptions
            );
            navigate("/");
          }
        } finally {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (
          e.response != undefined &&
          e.response.data != undefined &&
          e.response.data.error != undefined
        ) {
          props.onErrorResponse(e.response.data.error);
        } else setErrorMessage("Incorrect username or password");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const handleUnload = () => {
      removeAccessToken();
      cookie.remove("accessToken", {path: "/"});
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [removeAccessToken]);

  const getErrorMessage = (fieldName: string) => {
    if (errorMessage && errorMessage.length > 0) {
      return (
        <div
          className={styles.errorMessageContainer}
          data-testid={`error-message_${fieldName}`}
        >
          <img src={errorIcon} alt="error icon" className={styles.errorIcon}/>
          {errorMessage}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <img src={loaderClip} alt="loader" className={styles.loader}/>
        </div>
      )}
      {!isLoading && (
        <div>
          <div className={styles.text}>Login</div>
          <div className={styles.fieldContainer} data-testid="login-form">
            {formFields.map((field) => {
              return (
                <div key={field.id} className={styles.fieldContainer}>
                  <label htmlFor={field.name} className={styles.formLabel}>
                    {field.label}
                  </label>
                  <input
                    className={classNames(styles.formInput,
                      form[field.name as keyof FormFields].isError ? styles.inputError : ""
                    )}
                    value={form[field.name as keyof FormFields].data}
                    type={field.type}
                    onChange={field.onchange}
                    id={field.id}
                    name={field.name}
                    placeholder={field.placeholder}
                  />
                </div>
              );
            })}

            {getErrorMessage("username")}

            <div className={styles.formButtonContainer}>
              <button
                onClick={handleSubmit}
                className={styles.button}
                data-testid={submitButtonText}
              >
                {submitButtonText}
              </button>
            </div>

            <div
              data-testid="reset-password-text"
              className={styles.forgotPasswordText}
              onClick={() => props.onResetPassword()}
            >
              Reset Password
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default LoginForm;
