import React, {FC, useContext, useEffect, useState} from "react";
import paymentSuccess from "../../../images/rechargeSuccess.png";
import correctLogo from "../../../images/correctsymbol.png";
import styles from "./RechargeSuccess.module.css";
import {fetchUserProfile} from "../../../api/endpoint";
import {UserDetailsResponse} from "../../../models/response/UserDetaiilsResponse";
import {notyf} from "../../../notyf";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {PaymentType} from "../../../domains/PaymentType";

export type Props = {
  transactionId: string;
  amount: number;
  date: string;
  currency: string;
  status: string;
  isMailSent: boolean;
  paymentType: PaymentType
};

const RechargeSuccess: FC<Props> = (props) => {
  const [email, setEmail] = useState<string>("");
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    fetchUserProfile(accessToken ? accessToken : "")
      .then((userDetailsResponse: UserDetailsResponse) => {
        setEmail(userDetailsResponse.email);
      })
      .catch(() => {
        notyf.error("Unable to fetch account data");
      });
  }, [accessToken]);

  const getHeaderText = () => {
    return props.paymentType === PaymentType.RECHARGE ? 'Recharge Successful' : 'Invoice Payment Successful';
  };

  const getSubHeaderText = () => {
    return props.paymentType === PaymentType.RECHARGE
      ? 'We are processing the recharge and you will be notified via email'
      : 'We are processing the invoice payment and you will be notified via email';
  };

  const getRemarkText = () => {
    return props.paymentType === PaymentType.RECHARGE
      ? 'Your balance should be updated shortly. If you find any discrepancy, please contact our support:'
      : 'Your invoice payment is being processed. If you have any questions, please contact our support:';
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.roundBoxContainer} data-testid="payment-success">
        <div className={styles.leftContainer}>
          <div className="correctLogo">
            <img src={correctLogo} alt="correctLogo" />
          </div>
          <div className={styles.rechargeSuccess}>
            <h2>{getHeaderText()}</h2>
          </div>
          <p>{getSubHeaderText()}</p>
          <div className="image">
            <img src={paymentSuccess} alt="paymentSuccess" />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.dataRow}>
            <div className={styles.label}>Status</div>
            <div className={styles.value}>{props.status}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Transaction id</div>
            <div className={styles.value}>{props.transactionId}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Amount</div>
            <div className={styles.value}>{props.amount}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Currency</div>
            <div className={styles.value}>{props.currency}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Date</div>
            <div className={styles.value}>{props.date}</div>
          </div>
          <div className={styles.footDataContainer}>
            <div className={styles.remark}>Remark</div>
            <div className={props.isMailSent ? styles.yellowTextContainer : ""}>
              <div className={styles.yellowLeft}>
                {props.isMailSent && <> Confirmation email sent to: </>}
                {!props.isMailSent && (
                  <>
                    Unable to send confirmation email. This might be because you
                    have no email configured with your account.
                  </>
                )}
              </div>
              <div className={styles.yellowRight}>
                {props.isMailSent && <> {email} </>}
              </div>
            </div>
            <div className={styles.remarkText}>
              {getRemarkText()} support@skytelservices.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeSuccess;
