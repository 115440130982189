import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import styles from "./VirtualNumberExtension.module.css";
import { fetchExtensions } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { ExtensionStatus, GetExtensionResponse } from "../../../models/response/GetExtensionResponse";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import AddIcon from "@mui/icons-material/Add";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import AddExtensionDialog from "./AddExtension/AddExtension";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import {
  DeleteOutline,
  DialerSipOutlined,
  EditOutlined,
  FastForwardOutlined,
  ManageAccountsOutlined
} from "@mui/icons-material";
import ViewExtensionPassword from "./ViewExtensionPassword/ViewExtensionPassword";
import VirtualNumberRedirectionDetails from "./VirtualNumberRedirectionDetails/VirtualNumberRedirectionDetails";
import VirtualNumberRegistrationDetails from "./VirtualNumberRegistrationDetails/VirtualNumberRegistrationDetails";

const VirtualNumberExtension: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [isAddExtensionDialogOpen, setIsAddExtensionDialogOpen] = useState(false);
  const [selectedExtensionRedirections, setSelectedExtensionRedirections] = useState<GetExtensionResponse | null>(null);
  const [selectedExtensionRegistrations, setSelectedExtensionRegistrations] = useState<GetExtensionResponse | null>(null);

  const { data: extensions = [], isLoading, refetch: reFetchExtensions } = useQuery<GetExtensionResponse[]>(
    ["extensions", accessToken],
    () => fetchExtensions(accessToken || ""),
    {
      refetchInterval: (data) => {
        if (!data) return false;
        return data.some(extension => extension.status !== ExtensionStatus.AGENT_REGISTERED) ? 30000 : false;
      },
      enabled: !!accessToken,
      onError: () => {
        notyf.error("Failed to fetch extensions");
      }
    }
  );

  const getStatusClassAndTooltip = (status: ExtensionStatus) => {
    switch (status) {
      case ExtensionStatus.UNPROVISIONED:
        return {
          className: styles.inactiveStatus,
          tooltipText: "Extension is Inactive",
          testId: "status-unprovisioned"
        };
      case ExtensionStatus.CONFIGURED:
        return {
          className: styles.pendingStatus,
          tooltipText: "Extension is Configured",
          testId: "status-configured"
        };
      case ExtensionStatus.AGENT_REGISTERED:
        return {
          className: styles.activeStatus,
          tooltipText: "Extension is Registered on Softphone",
          testId: "status-agent-registered"
        };
      default:
        return {
          className: styles.pendingStatus,
          tooltipText: "Unknown Extension Status",
          testId: "status-unknown"
        };
    }
  };


  const headers = [
    () => <span>Extensions</span>,
    () => <span>Password</span>,
    () => <span>Status</span>,
    () => (
      <SkyButton
        size={ButtonSize.FIT_CONTENT}
        onClick={() => setIsAddExtensionDialogOpen(true)}
        testId="create-extension-button"
        renderer={() => (
          <div className={styles.createExtensionBtn}>
            <AddIcon />
            <span> Create Extension </span>
          </div>
        )}
      />
    )
  ];

  const data = extensions.map((extension) => [
    () => <span>{extension.username}</span>,
    () => (
      <ViewExtensionPassword
        extensionId={extension.id}
        status={extension.status}
      />
    ),
    () => {
      const { className, tooltipText, testId } = getStatusClassAndTooltip(extension.status);
      return (
        <SkyTooltip tooltipText={tooltipText}>
          <div className={`${styles.dot} ${className}`} data-testid={testId} />
        </SkyTooltip>
      );
    },
    () => (
      <div className={styles.actionIcons}>
        <SkyTooltip tooltipText="View Registration details">
          <span className={styles.icon}
            data-testid={"manage-account-icon"}
            onClick={() => setSelectedExtensionRegistrations(extension)}
          >
            <ManageAccountsOutlined />
          </span>
        </SkyTooltip>
        <SkyTooltip tooltipText="View Redirections">
          <span
            className={styles.icon}
            data-testid={"fast-forward-icon"}
            onClick={() => setSelectedExtensionRedirections(extension)}
          >
            <FastForwardOutlined />
          </span>
        </SkyTooltip>
        <SkyTooltip tooltipText={`SIP Host: ${extension.sipHost}`}>
          <span className={styles.icon} data-testid={"dialer-sip-icon"}><DialerSipOutlined /></span>
        </SkyTooltip>
        <span className={styles.icon} data-testid={"edit-icon"}><EditOutlined /></span>
        <span className={styles.icon} data-testid={"delete-icon"}><DeleteOutline /></span>
      </div>
    )
  ]);

  return (
    <div className={styles.virtualNumberExtension}>
      <div className={styles.virtualNumberExtensionData}>
        <SkyGrid
          isLoading={isLoading}
          headerRenderer={headers}
          renderer={data}
          colorBarSpan={3}
        />
        {isAddExtensionDialogOpen && (
          <AddExtensionDialog
            isOpen={isAddExtensionDialogOpen}
            onClose={() => setIsAddExtensionDialogOpen(false)}
            onSuccess={reFetchExtensions}
          />
        )}
        {selectedExtensionRedirections && (
          <VirtualNumberRedirectionDetails
            open={!!selectedExtensionRedirections}
            onClose={() => setSelectedExtensionRedirections(null)}
            redirections={selectedExtensionRedirections.numberRedirections}
          />
        )}
        {selectedExtensionRegistrations && (
          <VirtualNumberRegistrationDetails
            open={!!selectedExtensionRegistrations}
            onClose={() => setSelectedExtensionRegistrations(null)}
            registrations={selectedExtensionRegistrations.registrations}
          />
        )}
      </div>
    </div>
  );
};

export default VirtualNumberExtension;