import React, {FC, useContext, useEffect, useRef, useState} from "react";
import styles from "./KycForm.module.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BusinessRequirementForm from "./BusinessRequirementForm/BusinessRequirementForm";
import AddressDetailsForm from "./AddressDetailsForm/AddressDetailsForm";
import statusbarCircle from "../../../icons/statusbarCircle.png";
import classNames from "classnames";
import CompanyDetailsForm from "./CompanyDetailsForm/CompanyDetailsForm";
import TaxDetailsForm from "./TaxDetailsForm/TaxDetailsForm";
import PersonalDetailsForm from "./PersonalDetailsForm/PersonalDetailsForm";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BankDetailsForm from "./BankDetailsForm/BankDetailsForm";
import {notyf} from "../../../notyf";
import {fetchLatestKycData, fetchUserProfile, saveKycData} from "../../../api/endpoint";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {
  AddressDetails,
  BankDetails,
  blankKYCData,
  BusinessDetails,
  CompanyDetails,
  PersonalDetails,
  isAllValid,
  KYCData,
  TaxDetails,
  ValidationResult,
} from "../../../domains/KYC";
import SummaryForm from "./SummaryForm/SummaryForm";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";
import DotLoader from "../DotLoader/DotLoader";
import {UserDetailsContext} from "../../../contextApi/UserDetailsContext/UserDetailsContext";
import {useQuery} from "react-query";
import {AxiosError} from "axios";

type KycFormProps = {
  onClose: () => void;
};

const KycForm: FC<KycFormProps> = (props) => {
  const {accessToken} = useContext(AuthContext);
  const [currentForm, setCurrentForm] = useState<number>(0);
  const [isUploadOpen] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [consentCheckBoxChecked, setConsentCheckboxChecked] =
    useState<boolean>(false);

  const [kycData, setKycData] = useState<KYCData>(blankKYCData);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const filledStatusBar = useRef<HTMLDivElement>(null);
  const iconContainer = useRef<HTMLDivElement>(null);
  const {user, setUser} = useContext(UserDetailsContext);

  const forms: (() => JSX.Element)[] = [
    () => (
      <BusinessRequirementForm
        kycData={kycData.businessDetails}
        onChange={handleBusinessDetailsChange}
        key={kycData.businessDetails.businessType}
        isValid={validationResult?.businessDetailsValidation!}
      />
    ),
    () => (
      <CompanyDetailsForm
        kycData={kycData.companyDetails}
        onChange={handleCompanyDetailsChange}
        isValid={validationResult?.companyDetailsValidation!}
      />
    ),
    () => (
      <TaxDetailsForm
        kycData={kycData.taxDetails}
        onChange={handleTaxDetailsChange}
        isValid={validationResult?.taxDetailsValidation!}
      />
    ),
    () => (
      <AddressDetailsForm
        kycData={kycData.addressDetails}
        onChange={handleAddressDetailsChange}
        isValid={validationResult?.addressDetailsValidation!}
      />
    ),
    () => (
      <BankDetailsForm
        kycData={kycData.bankDetails}
        onChange={handleBankDetailsChange}
      />
    ),
    () => (
      <PersonalDetailsForm
        kycData={kycData.personalDetails}
        onChange={handleIdentityProofChange}
      />
    ),
    () => (
      <SummaryForm
        redirectTo={(index) => setCurrentForm(index)}
        kycData={kycData}
        onValidate={setValidationResult}
      />
    ),
  ];

  const {isFetching: isKycDataFetching, data: existingKycData} = useQuery(
    "existingKycData",
    () => fetchLatestKycData(accessToken ?? "", user?.id ?? ""),
    {
      onError: (err: AxiosError) => {
        if(err.response?.status !== 404)
          notyf.error("Failed to fetch KYC data!");
      }
    }
  );

  useEffect(() => {
    if (existingKycData)
      setKycData(JSON.parse(existingKycData?.data));
  }, [existingKycData]);

  const updateUserDetailsContext = () => {
    fetchUserProfile(accessToken ? accessToken : "")
      .then((userProfile) => {
        setUser(userProfile);
      })
      .catch(() => {
        notyf.error("Unable to fetch user profile");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBusinessDetailsChange = (details: BusinessDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      businessDetails: details,
    }));
  };

  const handleTaxDetailsChange = (details: TaxDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      taxDetails: details,
    }));
  };

  const handleCompanyDetailsChange = (details: CompanyDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      companyDetails: details,
    }));
  };

  const handleAddressDetailsChange = (details: AddressDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      addressDetails: details,
    }));
  };

  const handleBankDetailsChange = (details: BankDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      bankDetails: details,
    }));
  };

  const handleIdentityProofChange = (details: PersonalDetails) => {
    setKycData((kycData) => ({
      ...kycData,
      personalDetails: details,
    }));
  };

  const handleKycSubmit = () => {
    if (!validationResult) return;
    if (!isAllValid(validationResult)) {
      setErrorMessage("Please fix the errors before submit");
      return;
    }
    setIsLoading(true);
    saveKycData(accessToken ? accessToken : "", {
      kycData: JSON.stringify(kycData),
    })
      .then((_) => {
        updateUserDetailsContext();
        props.onClose();
        notyf.success(
          "KYC Submitted Successfully. You will be notified when review will be completed."
        );
      })
      .catch((_) => {
        setIsLoading(false);
        notyf.error("Unable to submit KYC. Please try again later.");
      });
  };

  useEffect(() => {
    const statusBarElement = filledStatusBar.current;
    const iconContainerElement = iconContainer.current;

    if (
      statusBarElement instanceof HTMLElement &&
      iconContainerElement instanceof HTMLElement
    ) {
      statusBarElement.style.setProperty("--percentage", `${percentage}`);
      iconContainerElement.style.setProperty("--percentage", `${percentage}`);
    }
  }, [percentage]);

  useEffect(() => {
    setPercentage((currentForm / (forms.length - 1)) * 100);
    setErrorMessage("");
  }, [currentForm]);

  const goNext = () => {
    setCurrentForm(currentForm + 1);
  };

  const goPrev = () => {
    setCurrentForm(currentForm - 1);
  };

  const isNotFirstForm = () => currentForm != 0;

  const isNotLastForm = () => currentForm != forms.length - 1;

  const isLastForm = () => !isNotLastForm();

  return (
    <>{isKycDataFetching &&
        <>
            <div className={styles.kycInfoLoader}><DotLoader/></div>
            <div
                className={styles.overlay}
                data-testid={"kyc-form-overlay"}/>
        </>
    }
      <div className={styles.kycFormContainer}>
        <div className={styles.header}>
          <CancelOutlinedIcon
            data-testid="cancel-icon"
            className={styles.cancelIcon}
            onClick={() => props.onClose()}
          />
        </div>

        {currentForm !== null && !isUploadOpen && (
          <div className={styles.statusBarContainer}>
            <div
              className={styles.statusBarWrapperContainer}
              data-testid="statusbar-container"
            >
              <div
                className={classNames(
                  styles.filledStatusBar,
                  styles.dynamicWidth
                )}
                data-testid="filled-statusbar"
                ref={filledStatusBar}
              />
              <div
                className={classNames(styles.iconContainer, styles.dynamicLeft)}
                data-testid="icon-container"
                ref={iconContainer}
              >
                <img
                  src={statusbarCircle}
                  alt="statusCircle"
                  className={styles.circleIcon}
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles.body}>
          {forms[currentForm]()}
          {isLastForm() && (
            <>
              <div className={styles.consentContainer}>
                <input
                  type="checkbox"
                  className={styles.consentCheckbox}
                  checked={consentCheckBoxChecked}
                  onChange={() =>
                    setConsentCheckboxChecked(!consentCheckBoxChecked)
                  }
                  data-testid="consent-checkbox"
                />
                <span className={styles.consentText}>
                I give my consent to store my details to Skytel Digicom
              </span>
              </div>

              <div className={styles.kycButtonContainer}>
                {isLoading ? (
                  <DotLoader/>
                ) : (
                  <SkyButton
                    text={"Submit"}
                    onClick={() => handleKycSubmit()}
                    size={ButtonSize.SMALL}
                    disabled={!consentCheckBoxChecked}
                    testId={"kyc-submit-button"}
                  />
                )}
              </div>

              {errorMessage.length > 0 && (
                <div className={styles.error}> {errorMessage} </div>
              )}
            </>
          )}
        </div>

        <div className={styles.footer}>
          {isNotFirstForm() && (
            <button
              className={styles.navigateButton}
              onClick={() => goPrev()}
              data-testid="prev-arrow-button"
            >
              <ArrowRightAltIcon
                className={classNames(styles.arrowRightIcon, styles.rotate180deg)}
              />
            </button>
          )}
          {isNotLastForm() && (
            <button
              className={styles.navigateButton}
              onClick={() => goNext()}
              data-testid="next-arrow-button"
            >
              <ArrowRightAltIcon className={styles.arrowRightIcon}/>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default KycForm;
