import React, {FC, useEffect, useState} from "react";
import styles from "../DetailForm.module.css";
import UploadForm from "../UploadForm/UploadForm";
import error from "../../../../icons/error-emoji.png";
import {CompanyDetails, documentToBase64} from "../../../../domains/KYC";
import SkyInputBox from "../../../base/SkyInputBox/SkyInputBox";
import DocumentUploader from "../DocumentUploader/DocumentUploader";

type CompanyRegistrationProps = {
  kycData: CompanyDetails;
  onChange: (details: CompanyDetails) => void;
  isValid: boolean;
};

const CompanyDetailsForm: FC<CompanyRegistrationProps> = (props) => {
  const [companyName, setCompanyName] = useState<string>(
    props.kycData.companyName
  );
  const [address, setAddress] = useState<string>(props.kycData.address);
  const [city, setCity] = useState<string>(props.kycData.city);
  const [state, setState] = useState<string>(props.kycData.state);
  const [zip, setZip] = useState<string>(props.kycData.zip);
  const [country, setCountry] = useState<string>(props.kycData.country);
  const [supportingDocument, setSupportingDocument] = useState<string>(
    props.kycData.supportingDocument
  );
  const [isOpenUploadOption, setIsOpenUploadOption] = useState<boolean>(false);
  const [errorMessage] = useState<string | null>("");
  const handleUploadDoc = () => {
    setIsOpenUploadOption(true);
  };

  const handleUploadDocClose = () => {
    setIsOpenUploadOption(false);
  };

  const handleFileSelect = async (file: File | string) => {
    if (file instanceof File) {
      const base64 = await documentToBase64(file);
      setSupportingDocument(base64 as string);
    } else setSupportingDocument(file as string);
  };

  useEffect(() => {
    props.onChange({
      companyName: companyName,
      address: address,
      city: city,
      state: state,
      zip: zip,
      country: country,
      supportingDocument: supportingDocument,
    });
  }, [companyName, address, city, state, zip, country, supportingDocument]);

  return (
    <div className={styles.formContainer} data-testid="company-details-form">
      {isOpenUploadOption ? (
        <UploadForm
          onClose={handleUploadDocClose}
          onFileSelect={handleFileSelect}
        />
      ) : (
        <div className={styles.wrapperContainer}>
          <div className={styles.leftContainer}>
            <DocumentUploader
              handleUploadDoc={handleUploadDoc}
              supportingDocument={supportingDocument}
              supportedDocumentsHelpText={"Company Registration Certificate, Trade Licenses, Rent Agreement"}
              isValid={props.isValid === undefined || props.isValid || supportingDocument.length !== 0}
            />
          </div>
          <div className={styles.rightContainer}>
            <label>
              Fill Up Your Company Details
              <br/>
              (All fields are mandatory)
            </label>
            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || companyName.length !== 0}
                onChange={(value) => setCompanyName(value as string)}
                placeholder={"Company Name"}
                type={"text"}
                value={companyName}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || address.length !== 0}
                onChange={(value) => setAddress(value as string)}
                placeholder={"Address"}
                type={"text"}
                value={address}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || city.length !== 0}
                onChange={(value) => setCity(value as string)}
                placeholder={"City"}
                type={"text"}
                value={city}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || state.length !== 0}
                onChange={(value) => setState(value as string)}
                placeholder={"State / Origin / County"}
                type={"text"}
                value={state}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || zip.length !== 0}
                onChange={(value) => setZip(value as string)}
                placeholder={"Zip Code"}
                type={"text"}
                value={zip}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={props.isValid === undefined || props.isValid || country.length !== 0}
                onChange={(value) => setCountry(value as string)}
                placeholder={"Country"}
                type={"text"}
                value={country}
              />
            </div>

            {errorMessage && (
              <div className={styles.error}>
                <img src={error} alt="error-icon"/>
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CompanyDetailsForm;
