import React, { FC, useEffect, useState } from "react";
import MenuTab from "../../components/business/MenuTab/menuTab";
import { Menu, SubMenu } from "../../domains/Menu";
import styles from "./ServiceScreen.module.css";
import TransactionSheet from "../../components/service/TransactionSheet/TransactionSheet";
import { getMenu, getSubMenu, UIService } from "../../domains/UIService";
import RateSheet from "../../components/service/RateList/RateSheet";
import CDRContainer from "../../components/service/CDRManagement/CDRContainer";
import CSRContainer from "../../components/service/CSRView/CSRContainer";
import IPTrunkList from "../../components/service/IPTrunkList/IPTrunkList";
import UserTrunkList from "../../components/service/UserTrunkList/UserTrunkList";
import VirtualNumberList from "../../components/service/VirtualNumbersList/VirtualNumberList";
import OtherChargesList from "../../components/service/OtherChargesList/OtherChargesList";
import VirtualNumberPurchase from "../../components/service/VirtualNumberPurchase/VirtualNumberPurchase";
import VirtualNumberExtension from "../../components/service/VirtualNumberExtension/VirtualNumberExtension";

export type Props = {
  service: UIService;
};

const ServiceScreen: FC<Props> = (props) => {
  const [selectedMenu, setSelectedMenu] = useState<Menu>(Menu.UNKNOWN);
  const [selectedSubMenu, setSelectedSubMenu] = useState<SubMenu>(
    SubMenu.UNKNOWN
  );

  useEffect(() => {
    setSelectedSubMenu(getSubMenu(props.service));
    setSelectedMenu(getMenu(props.service));
  }, [props.service]);

  // @ts-ignore
  return (
    <>
      <MenuTab selectedMenu={selectedMenu} selectedSubMenu={selectedSubMenu} />
      <div className={styles.serviceArea}>
        {props.service == UIService.SHOW_RECHARGES && <TransactionSheet />}
        {props.service == UIService.RATES && <RateSheet />}
        {props.service == UIService.VIEW_CDR && <CDRContainer />}
        {props.service == UIService.VIEW_CSR && <CSRContainer />}
        {props.service == UIService.IP_TRUNK_LIST && <IPTrunkList />}
        {props.service == UIService.USER_TRUNK_LIST && <UserTrunkList />}
        {props.service == UIService.VIRTUAL_NUMBER_LIST && (
          <VirtualNumberList />
        )}
        {props.service == UIService.PURCHASE_VIRTUAL_NUMBER && (
          <VirtualNumberPurchase />
        )}
        {props.service == UIService.SHOW_OTHER_TRANSACTIONS && (
          <OtherChargesList />
        )}
        {props.service == UIService.CREATE_EXTENSION && <VirtualNumberExtension />}
        {/*{props.service == UIService.CDR_SEARCH && (<CDRSearch />)}*/}
      </div>
    </>
  );
};

export default ServiceScreen;
