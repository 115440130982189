import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./TransactionSheet.module.css";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchTransactions } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { FetchTransactionsResponse } from "../../../models/response/FetchTransactionsResponse";

type TransactionData = {
  id: string;
  date: string;
  amountPaid: number;
  balance: number;
  time: string;
};

const TransactionSheet: FC = () => {
  const [transactionData, setTransactionData] = useState<TransactionData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accessToken } = useContext(AuthContext);

  const columns: string[] = [
    "ID",
    "Paid Amount",
    "Final Value",
    "Date",
    "Time",
  ];

  useEffect(() => {
    try {
      fetchTransactions(accessToken ? accessToken : "")
        .then((fetchTransactionsResponse: FetchTransactionsResponse[]) => {
          try {
            setTransactionData(
              fetchTransactionsResponse.map((transaction) => {
                return {
                  id: transaction.id,
                  date: transaction.date,
                  amountPaid: transaction.amountPaid,
                  balance: transaction.balance,
                  time: transaction.time,
                };
              })
            );
            setIsLoading(false);
          } catch (error) {
            notyf.error("Unable to process data");
          }
        })
        .catch(() => {
          notyf.error("Unable to fetch data");
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      notyf.error("Unable to make communicate with the system.");
    }
  }, []);

  return (
    <div className={styles.container} data-testid="recharge-sheet">
      <div className={styles.tableContainer}>
        <SkyGrid
          columns={columns}
          isLoading={isLoading}
          data={transactionData.map((obj, index) => {
            return [
              index + 1 + "",
              parseFloat(String(obj.amountPaid)).toFixed(2) + "",
              obj.balance + "",
              obj.date,
              obj.time,
            ];
          })}
        />
      </div>
    </div>
  );
};
export default TransactionSheet;
