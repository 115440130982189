import {createContext, useEffect, useState} from "react";
import {MenuItem, topMenuList, Menu} from "../../domains/Menu";
import {useLocation} from "react-router-dom";

type SelectedMenuItemContextType = {
  selectedItem: MenuItem | null;
  setSelectedItem: (item: MenuItem | null) => void;
};

export const SelectedScreenContext = createContext<SelectedMenuItemContextType>(
  {
    selectedItem: null,
    setSelectedItem: () => {},
  })

export const SelectedScreenContextProvider = ({children}) => {
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(
    topMenuList.find((item) => item.name === Menu.DASHBOARD) || null
  );

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path.includes("dashboard")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.DASHBOARD) || null);
    } else if (path.includes("siptrunk")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.SIP_TRUNK) || null);
    } else if (path.includes("numbers")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.NUMBERS) || null);
    } else if (path.includes("cloudtelephony")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.CLOUD_TELEPHONY) || null);
    } else if (path.includes("pricing")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.PRICING) || null);
    } else if ((path.includes("report") || path.includes("transactions/recharge"))) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.REPORT) || null);
    } else if (path.includes("recharge")) {
      setSelectedItem(topMenuList.find((item) => item.name === Menu.RECHARGE) || null);
    }
  }, [location.pathname]);

  return (
    <SelectedScreenContext.Provider
      value={{
        selectedItem,
        setSelectedItem
      }}
    >
      {children}
    </SelectedScreenContext.Provider>
  )
}