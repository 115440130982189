import React, { createContext, useState } from "react";
import { AxiosError } from "axios";
import OTPForm from "../../components/business/OTPForm/OTPForm";

type OTPContextDataType = {
  setOTPRequired: (isRequired: boolean) => void;
  otpRequired: boolean;
  setErrorResponse: (request: AxiosError) => void;
  errorResponse: AxiosError | null;
  isOTPVerificationSuccess: boolean;
  setIsOTPVerificationSuccess: (isOTPVerificationSuccess: boolean) => void;
  otpVerificationResponse: any;
  setOTPVerificationResponse: (response: any) => void;
};

export const OTPContext = createContext<OTPContextDataType>({
  setOTPRequired: () => {},
  otpRequired: false,
  setErrorResponse: () => {},
  errorResponse: null,
  isOTPVerificationSuccess: false,
  setIsOTPVerificationSuccess: () => {},
  otpVerificationResponse: null,
  setOTPVerificationResponse: () => {},
});

export const OTPContextProvider = ({ children }) => {
  const [otpRequired, setOTPRequired] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<AxiosError | null>(null);
  const [isOTPVerificationSuccess, setIsOTPVerificationSuccess] = useState<boolean>(false);
  const [otpVerificationResponse, setOTPVerificationResponse] = useState<any>(null);

  return (
    <OTPContext.Provider
      value={{
        setOTPRequired,
        otpRequired,
        setErrorResponse,
        errorResponse,
        isOTPVerificationSuccess,
        setIsOTPVerificationSuccess,
        otpVerificationResponse,
        setOTPVerificationResponse,
      }}
    >
      {children}
      <OTPForm />
    </OTPContext.Provider>
  );
};
