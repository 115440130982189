import {createContext, ReactNode, useState} from "react";
import {CartProducts} from "../../domains/CartProducts";

type CartContextType = {
  cartProducts: CartProducts[];
  addProduct: (products: CartProducts[]) => void;
  clearCart: () => void;
  total: number;
  totalWithFee: number;
  paymentGatewayFee: number;
}

export const CartContext = createContext<CartContextType>({
  cartProducts: [],
  addProduct: () => {},
  clearCart: () => {},
  total: 0,
  totalWithFee: 0,
  paymentGatewayFee: 0
})

type CartContextProviderProps = {
  children: ReactNode;
};

export const CartContextProvider = ({children}: CartContextProviderProps) => {
  const [cartProducts, setCartProducts] = useState<CartProducts[]>([]);

  const addProduct = (products: CartProducts[]) => {
    setCartProducts((prevProducts) => [...prevProducts, ...products]);
  };

  const clearCart = () => {
    setCartProducts([]);
  };

  const total = cartProducts
    .reduce((sum, product) =>
      sum + (product.price * product.quantity), 0);

  const paymentGatewayFee = total * 0.05;

  const totalWithFee = total + paymentGatewayFee

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        addProduct,
        clearCart,
        total,
        totalWithFee,
        paymentGatewayFee
      }}
    >
      {children}
    </CartContext.Provider>
  )
}