import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {
    RadioButtonChecked,
    RadioButtonUnchecked,
    KeyboardArrowUp,
    KeyboardArrowDown
} from "@mui/icons-material";
import {useQuery} from "react-query";
import classNames from "classnames";
import styles from "./RedirectNumberForm.module.css";
import errorIcon from "../../../../icons/error-emoji.png";
import {AuthContext} from "../../../../contextApi/AuthContext/authContext";
import {OTPContext} from "../../../../contextApi/OTPContext/OTPContext";
import DotLoader from "../../../business/DotLoader/DotLoader";
import {notyf} from "../../../../notyf";
import SkyButton, {ButtonSize} from "../../../base/SkyButton/SkyButton";
import SkyDropdown from "../../../base/SkyDropdown/SkyDropdown";
import {fetchExtensions, fetchIpTrunkList, redirectVirtualNumber} from "../../../../api/endpoint";
import {isOTPRequired} from "../../../../requester";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SkyTooltip from "../../../base/SkyTooltip/SkyTooltip";
import AddExtensionDialog from "../../VirtualNumberExtension/AddExtension/AddExtension";
import { RedirectionInfo } from "../../../../models/response/VirtualNumbersResponse";
import { ExtensionStatus } from "../../../../models/response/GetExtensionResponse";
// import AddIPDialog from "../../VirtualNumberIp/AddIP/AddIP";

enum RedirectType {
    Extension,
    IP
}

type Props = {
    virtualNumber: string;
    redirectionInfo?: RedirectionInfo | null;
    onCancel: () => void;
    onSuccess: () => void;
};

const RedirectVirtualNumberForm: FC<Props> = ({virtualNumber, onCancel, onSuccess, redirectionInfo}) => {
    const [redirectType, setRedirectType] = useState<RedirectType>(RedirectType.Extension);
    const [selectedExtension, setSelectedExtension] = useState("");
    const [selectedExtensionId, setSelectedExtensionId] = useState("");
    const [isExtensionDropdownOpen, setIsExtensionDropdownOpen] = useState(false);
    const [selectedIP, setSelectedIP] = useState("");
    const [isIPDropdownOpen, setIsIPDropdownOpen] = useState(false);
    const [isExtensionDialogOpen, setIsExtensionDialogOpen] = useState(false);
    const [isIPDialogOpen, setIsIPDialogOpen] = useState(false);
    const [isRedirectionSuccess, setIsRedirectionSuccess] = useState(false);
    const ipSelectorRef = useRef<HTMLDivElement>(null);
    const {accessToken} = useContext(AuthContext);
    const [isRedirectionAttempted, setIsRedirectionAttempted] = useState(false);
    const [isRedirectProcessing, setIsRedirectProcessing] = useState(false);

    const {
        setOTPRequired,
        setErrorResponse,
        isOTPVerificationSuccess,
        setIsOTPVerificationSuccess,
    } = useContext(OTPContext);

    useEffect(() => {
        if (isOTPVerificationSuccess && isRedirectionAttempted) {
            handleRedirectSuccess();
            setIsOTPVerificationSuccess(false);
            setIsRedirectionAttempted(false);
        }
    }, [isOTPVerificationSuccess, isRedirectionAttempted]);

    useEffect(() => {
        resetForm();
    }, [redirectType]);

    useEffect(() => {
        if (redirectionInfo) {
            setRedirectType(
              redirectionInfo.type === 'EXTENSION'
                ? RedirectType.Extension
                : RedirectType.IP
            );

            if (redirectionInfo.type === 'EXTENSION') {
                setSelectedExtension(redirectionInfo.redirectionDetails.details);
                setSelectedExtensionId(redirectionInfo.redirectionDetails.id)
            } else {
                setSelectedIP(redirectionInfo.redirectionDetails.details);
            }
        }
    }, [redirectionInfo]);

    const handleRedirectSuccess = () => {
        setIsRedirectionSuccess(true);
        notyf.success("Virtual number redirected successfully");
    };

    const handleRedirectError = (error: any) => {
        if (isOTPRequired(error)) {
            setOTPRequired(true);
            setErrorResponse(error);
        } else {
            notyf.error("Failed to redirect virtual number");
        }
    };

    const { isFetching: isExtensionsFetching, data: extensions, isError: isExtensionsFetchError, refetch: reFetchExtensions } = useQuery(
        "fetchExtensions",
        () => fetchExtensions(accessToken || ""),
        {
            enabled: isExtensionDropdownOpen,
            onError: () => notyf.error("Unable to fetch extensions"),
        }
    );

    const activeExtensions = extensions?.filter((extension) => extension.status !== ExtensionStatus.UNPROVISIONED) || [];

    const {isFetching: isIPFetching, data: ipTrunkList, isError: isIPFetchError} = useQuery(
        "fetchIPTrunkList",
        () => fetchIpTrunkList(accessToken ? accessToken : ""),
        {
            enabled: isIPDropdownOpen,
            onError: () => notyf.error("Unable to fetch existing IP list")
        }
    );

    const handleSubmit = async () => {
        if (!accessToken) {
            return;
        }

        setIsRedirectProcessing(true);
        setIsRedirectionAttempted(true);

        try {
            const request = {
                type: redirectType === RedirectType.Extension ? 'EXTENSION' : 'IP',
                destinationId: redirectType === RedirectType.Extension ? selectedExtensionId : selectedIP
            };

            await redirectVirtualNumber(accessToken, virtualNumber, request);
            handleRedirectSuccess();
        } catch (error) {
            handleRedirectError(error);
        } finally {
            setIsRedirectProcessing(false);
        }
    };

    const getErrorMessageElement = (message: string) => {
        return (
            <div className={styles.errorMessageContainer} data-testid="error-message">
                <img src={errorIcon} alt="error icon" className={styles.errorIcon}/>
                {message}
            </div>
        );
    };

    const resetForm = () => {
        setIsIPDropdownOpen(false);
        setIsExtensionDropdownOpen(false);
        setSelectedIP("");
        setSelectedExtension("");
        setSelectedExtensionId("")
    };

    if (isRedirectionSuccess) {
        return (
            <div className={classNames(styles.addVirtualNumberOuterContainer, styles.confirmationContainer)}>
            <span className={styles.confirmationHeader}>
                Redirection Successful
            </span>
                <span className={styles.confirmationText} data-testid="confirmation-text">
                Virtual number {virtualNumber} has been redirected to{' '}
                    {redirectType === RedirectType.Extension ? `extension ${selectedExtension}` : `IP ${selectedIP}`}.
            </span>
                <SkyButton
                    onClick={onSuccess}
                    size={ButtonSize.SMALL}
                    testId="ok-button"
                    text="Ok"
                />
            </div>
        );
    }

    return (
        <>
            <div className={styles.addVirtualNumberOuterContainer}>
                <div className={classNames(styles.configurationSelector, styles.grayFont)}>
                    <div
                        onClick={() => setRedirectType(RedirectType.Extension)}
                        className={styles.clickable}
                        data-testid="redirect-to-extension"
                    >
                        {redirectType === RedirectType.Extension ? (
                            <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)}/>
                        ) : (
                            <RadioButtonUnchecked className={styles.grayFont}/>
                        )}
                        <span>Redirect to Extension</span>
                    </div>

                    <div
                        onClick={() => setRedirectType(RedirectType.IP)}
                        className={styles.clickable}
                        data-testid="redirect-to-ip"
                    >
                        {redirectType === RedirectType.IP ? (
                            <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)}/>
                        ) : (
                            <RadioButtonUnchecked className={styles.grayFont}/>
                        )}
                        <span>Redirect to IP</span>
                    </div>
                </div>

                <div className={styles.addVirtualNumberContainer} data-testid="redirect-form">
                    <div className={styles.addVirtualNumberRow}>
                        <div className={styles.addVirtualNumberCell}>
                            {redirectType === RedirectType.Extension ? (
                                <div className={styles.existingExtensionContainer}>
                                    <div className={styles.textLabel}>Extension Number</div>
                                    <div className={styles.combinedInputContainer}>
                                        <div
                                            className={classNames(styles.roundControl, styles.ipSelector)}
                                            onClick={() => setIsExtensionDropdownOpen(!isExtensionDropdownOpen)}
                                            data-testid="extension-dropdown"
                                        >
                                            <span>{selectedExtension || "Select an extension"}</span>
                                            {isExtensionDropdownOpen ? (
                                                <KeyboardArrowUp data-testid="extension-selector-arrow-up" />
                                            ) : (
                                                <KeyboardArrowDown data-testid="extension-selector-arrow-down" />
                                            )}
                                        </div>
                                        <SkyTooltip tooltipText={"Add New Extension"}>
                                            <AddCircleOutlineIcon
                                                className={styles.addIcon}
                                                onClick={() => {
                                                    resetForm();
                                                    setIsExtensionDialogOpen(true);
                                                }}
                                                data-testid={"add-extension-Icon"}
                                            />
                                        </SkyTooltip>
                                    </div>
                                    {isExtensionDropdownOpen && (
                                      <div className={styles.dropDownContainer}>
                                      <SkyDropdown
                                        data={
                                            activeExtensions
                                              ? activeExtensions.map((ext) => ({
                                                  id: ext.id,
                                                  value: ext.username,
                                              }))
                                              : []
                                        }
                                        onSelect={(data) => {
                                            setSelectedExtension(data.value);
                                            setSelectedExtensionId(data.id);
                                            setIsExtensionDropdownOpen(false);
                                        }}
                                        isSearchable={true}
                                        isLoading={isExtensionsFetching}
                                      />
                                      </div>
                                    )}

                                    {isExtensionsFetchError && getErrorMessageElement("Failed to fetch Extensions")}
                                </div>
                            ) : (
                                <div className={styles.existingIPContainer} ref={ipSelectorRef}>
                                    <div className={styles.textLabel}>Select IP</div>
                                    <div className={styles.combinedInputContainer}>
                                        <div
                                            className={classNames(styles.roundControl, styles.ipSelector)}
                                            onClick={() => setIsIPDropdownOpen(!isIPDropdownOpen)}
                                            data-testid="ip-dropdown"
                                        >
                                            <span>{selectedIP || "Select an IP"}</span>
                                            {isIPDropdownOpen ? (
                                                <KeyboardArrowUp data-testid="ip-selector-arrow-up"/>
                                            ) : (
                                                <KeyboardArrowDown data-testid="ip-selector-arrow-down"/>
                                            )}
                                        </div>
                                        <SkyTooltip
                                            tooltipText={"Add New IP"}
                                        >
                                            <AddCircleOutlineIcon
                                                onClick={() => {
                                                    resetForm();
                                                    setIsIPDialogOpen(true);
                                                }}
                                                className={styles.addIcon}
                                                data-testid={"add-IP-Icon"}
                                            />
                                        </SkyTooltip>
                                    </div>
                                    {isIPDropdownOpen && (
                                        <div className={styles.dropDownContainer}>
                                            <SkyDropdown
                                                data={!ipTrunkList ? [] : ipTrunkList.map(ipTrunk => ({
                                                    id: ipTrunk.gatewayName,
                                                    value: ipTrunk.remoteIP
                                                }))}
                                                onSelect={(data) => {
                                                    setSelectedIP(data.value);
                                                    setIsIPDropdownOpen(false);
                                                }}
                                                isSearchable={true}
                                                isLoading={isIPFetching}
                                            />
                                        </div>
                                    )}

                                    {isIPFetchError && getErrorMessageElement("Failed to fetch existing IPs")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {isRedirectProcessing ? (
                    <div className={styles.dotLoaderContainer}>
                        <DotLoader/>
                    </div>
                ) : (
                    <div className={styles.addVirtualNumberDialogButtonContainer}>
                        <SkyButton
                            text="Submit"
                            onClick={handleSubmit}
                            size={ButtonSize.SMALL}
                            testId="submit-button"
                            disabled={
                              isRedirectProcessing ||
                              (redirectType === RedirectType.Extension ? !selectedExtension : !selectedIP)
                            }
                        />
                        <SkyButton
                            text="Cancel"
                            onClick={onCancel}
                            size={ButtonSize.SMALL}
                            testId="cancel-button"
                        />
                    </div>
                )}
            </div>
            {isExtensionDialogOpen && (
                <AddExtensionDialog
                    isOpen={isExtensionDialogOpen}
                    onClose={() => setIsExtensionDialogOpen(false)}
                    onSuccess={reFetchExtensions}
                />
            )}
            {/*{isIPDialogOpen && (*/}
            {/*    <AddIPDialog*/}
            {/*        isOpen={isIPDialogOpen}*/}
            {/*        onClose={() => setIsIPDialogOpen(false)}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    );
};

export default RedirectVirtualNumberForm;