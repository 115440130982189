import React, { useContext, useState, useEffect, useRef } from "react";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import styles from "./ViewExtensionPassword.module.css";
import { ContentCopyOutlined, CheckCircleOutlined, VisibilityOutlined } from "@mui/icons-material";
import { notyf } from "../../../../notyf";
import { fetchExtensionsPassword } from "../../../../api/endpoint";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import { OTPContext } from "../../../../contextApi/OTPContext/OTPContext";
import DotLoader from "../../../business/DotLoader/DotLoader";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import classNames from "classnames";
import { isOTPRequired } from "../../../../requester";
import { ExtensionStatus } from "../../../../models/response/GetExtensionResponse";

type ViewExtensionPasswordProps = {
  extensionId: string;
  status: string;
};

const ViewExtensionPassword: React.FC<ViewExtensionPasswordProps> = ({ extensionId, status }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFetchingPassword, setIsFetchingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);

  const { accessToken } = useContext(AuthContext);
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
    otpVerificationResponse,
    setOTPVerificationResponse
  } = useContext(OTPContext);

  const pendingExtensionIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (isOTPVerificationSuccess && pendingExtensionIdRef.current === extensionId) {
      if (otpVerificationResponse) {
        setPassword(otpVerificationResponse.password);
      }
      setIsOTPVerificationSuccess(false);
      pendingExtensionIdRef.current = null;
    }
  }, [isOTPVerificationSuccess, extensionId]);

  const handlePasswordFetch = async () => {
    if (!accessToken) return;

    setIsFetchingPassword(true);
    try {
      await fetchExtensionsPassword(accessToken, extensionId);
      setIsFetchingPassword(false);
    } catch (error) {
      handlePasswordFetchError(error);
    }
  };

  const handleViewPassword = () => {
    setIsDialogOpen(true);
    setPassword("");
    setIsPasswordCopied(false);
    handlePasswordFetch();
  };

  const handlePasswordFetchError = (error: any) => {
    setIsFetchingPassword(false);
    if (isOTPRequired(error)) {
      setOTPRequired(true);
      setErrorResponse(error);
      pendingExtensionIdRef.current = extensionId;
    } else {
      const errorMessage = error?.response?.data?.message || "Failed to fetch password";
      notyf.error(errorMessage);
      setIsDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setPassword("");
    setIsPasswordCopied(false);
    pendingExtensionIdRef.current = null;
    setOTPVerificationResponse(null);
  };

  const handleCopyPassword = () => {
    if (password) {
      navigator.clipboard
        .writeText(password)
        .then(() => {
          setIsPasswordCopied(true);
          setTimeout(() => {
            setIsPasswordCopied(false);
          }, 1000);
        })
        .catch(() => {
          setIsPasswordCopied(false);
          notyf.error("Failed to copy password");
        });
    }
  };

  return (
    <>
      <div className={styles.passwordField}>
        <span>{"********"}</span>
        <span
          className={`${styles.icon} ${status === ExtensionStatus.UNPROVISIONED ? styles.disabled : ""}`}
          onClick={status !== ExtensionStatus.UNPROVISIONED ? handleViewPassword : undefined}
          data-testid="password-dialog"
        >
    <VisibilityOutlined />
    </span>
      </div>

      {isDialogOpen && (
        <Dialog
          size={Size.SM}
          header="View Extension Password"
          renderer={() => (
            <div className={classNames(styles.viewPasswordOuterContainer, styles.dialogContainer)}>
              <span className={styles.passwordHeader}>Password</span>
              {isFetchingPassword ? (
                <div className={styles.loaderContainer}>
                  <DotLoader />
                </div>
              ) : (
                <>
                  <div className={styles.passwordDisplayContainer}>
                    <div className={styles.passwordIconContainer}>
              <span className={styles.passwordText}>
                {password}
              </span>
                      <span
                        className={styles.icon}
                        onClick={handleCopyPassword}
                        data-testid="copy-password"
                      >
                {isPasswordCopied ? (
                  <CheckCircleOutlined />
                ) : (
                  <ContentCopyOutlined />
                )}
              </span>
                    </div>
                  </div>
                  <div className={styles.closeButton}>
                    <SkyButton
                      text="Close"
                      onClick={handleCloseDialog}
                      size={ButtonSize.SMALL}
                      data-testid="close-dialog-button"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        />
      )}
    </>
  );
};

export default ViewExtensionPassword;