import React from 'react'
import styles from "./AuthMobileBaseScreen.module.css";
import registerImage from "../../images/UserWithLaptop.png";
import loginImage from "../../images/login.svg";
import {Outlet} from "react-router-dom";

type Props = {
  isLogin: boolean;
}

const AuthMobileBaseScreen = (props: Props) => {
  return (
    <div className={styles.authMobileBaseContainer}>
      <div className={styles.welcomeSection}>
        <div className={styles.welcomeContent}>
          <div className={styles.welcomeTitle} aria-label="Welcome">
            {'Welcome'.split('').map((letter, index) => (
              <span
                key={index}
                className={styles.welcomeLetter}
                style={{animationDelay: `${index * 0.1}s`}}
              >
                  {letter}
                </span>
            ))}
          </div>
          <img
            src={!props.isLogin ? registerImage : loginImage}
            alt={!props.isLogin ? "register-image" : "login-image"}
            className={!props.isLogin ? styles.registerImage : styles.welcomeImage}
          />
        </div>
      </div>

      <div className={styles.formSection}>
        <Outlet/>
      </div>
    </div>
  )
}
export default AuthMobileBaseScreen
