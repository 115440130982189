import React, { FC } from "react";
import styles from "../DetailForm.module.css";
import documentIcon from "../../../../icons/document icon.png";
import SkyButton, {ButtonSize, ButtonType} from "../../../base/SkyButton/SkyButton";
import {InfoOutlined} from "@mui/icons-material";
import SkyTooltip from "../../../base/SkyTooltip/SkyTooltip";

export type DocumentUploaderProps = {
  handleUploadDoc: () => void;
  supportingDocument: string;
  supportedDocumentsHelpText?: string;
  disabled?: boolean;
  isValid:boolean;
};

const DocumentUploader: FC<DocumentUploaderProps> = (props) => {
  return (
    <>
      <p className={styles.leftContainerText}>Upload Supporting Document :</p>
      <div className={styles.uploadContainer}>
        <img
          src={props.supportingDocument || documentIcon}
          alt={
            props.supportingDocument ? "Uploaded File Preview" : "Document Icon"
          }
          className={styles.descriptionIcon}
        />

        <div className={styles.uploadHelpContainer}>
          <p className={styles.uploadText}> Upload JPEG / PNG </p>
          {props.supportedDocumentsHelpText && <SkyTooltip
              tooltipText={props.supportedDocumentsHelpText}
          >
              <InfoOutlined/>
          </SkyTooltip>}
        </div>


        <SkyButton
          text={"Upload"}
          onClick={props.handleUploadDoc}
          size={ButtonSize.SMALL}
          disabled={props.disabled}
          type={props.isValid ? ButtonType.DEFAULT : ButtonType.ERROR}
        />
      </div>
    </>
  );
};

DocumentUploader.defaultProps = {
  disabled: false
}

export default DocumentUploader;
