import React, { FC } from "react";
import styles from "../RechargePending/RechargePending.module.css";
import paymentPending from "../../../images/rechargePending.png";
import { PaymentType } from "../../../domains/PaymentType";

type Props = {
  paymentType: PaymentType;
};

const RechargePending: FC<Props> = ({ paymentType }) => {
  const getHeaderText = () => {
    return paymentType === PaymentType.RECHARGE ? 'Recharge Pending' : 'Invoice Payment Pending';
  };

  const getPendingText = () => {
    return paymentType === PaymentType.RECHARGE
      ? 'Your recharge is currently pending.'
      : 'Your invoice payment is currently pending.';
  };

  const getProcessingText = () => {
    return paymentType === PaymentType.RECHARGE
      ? 'We are processing your recharge and'
      : 'We are processing your invoice payment and';
  };

  return (
    <div className={styles.container}>
      <div className={styles.roundWhiteContainer}>
        <p className={styles.headerText}>{getHeaderText()}</p>
        <p className={styles.pendingText}>{getPendingText()}</p>
        <img
          alt="payment pending"
          className={styles.pendingImage}
          src={paymentPending}
        />
        <div className={styles.bottomContainer}>
          <p>
            {getProcessingText()}
            <span className={styles.messageHeader}>
              <br />you will receive an update via email shortly.
            </span>
          </p>
        </div>
        <div className={styles.supportTest}>
          <p>
            If you have any questions or need further assistance,
            please contact our support team at: <br />
            <span className={styles.emailText}> support@skytelservices.com </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RechargePending;