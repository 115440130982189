import React, {FC, useContext, useEffect, useState} from "react";
import pegionlogo from "../../../images/pegionlogo.png";
import dashboard from "../../../icons/dashboard.png";
import pricing from "../../../icons/pricing.png";
import siptrunk from "../../../icons/siptrunk.png";
import numbers from "../../../icons/number.png";
import setting from "../../../icons/setting.png";
import rightarrow from "../../../icons/rightarrow.png";
import report from "../../../icons/report.png";
import search from "../../../icons/search.png";
import leftarrow from "../../../icons/leftarrow.png";
import recharge from "../../../icons/recharge.png";
import cloudTelephony from "../../../icons/cloudTelephony.png";
import styles from "./menuBar.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../../../domains/Menu";
import { ViewPortSizeContext } from "../../../contextApi/ViewportSizeContext/ViewPortSizeContext";
import {SelectedScreenContext} from "../../../contextApi/SelectedScreenContext/SelectedScreenContext";
import { FeatureToggleContext } from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";

export type Props = {
  topMenuItems: MenuItem[];
  bottomMenuItems: MenuItem[];
  onToggle: (isExpanded: boolean) => void;
};

const MenuBar: FC<Props> = (props) => {
  const { isDIDFlowEnabled } = useContext(FeatureToggleContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchItem] = useState<string>("");
  const navigate = useNavigate();
  const [topMenuItems, setTopMenuItems] = useState<MenuItem[]>([]);
  const [bottomMenuItems, setBottomMenuItems] = useState<MenuItem[]>([]);
  const { setIsMenuBarExpanded } = useContext(ViewPortSizeContext);
  const { selectedItem } = useContext(SelectedScreenContext);

  useEffect(() => {
    const filteredTopMenuItems = props.topMenuItems.filter((item) =>
      item.name === "Cloud Telephony" ? isDIDFlowEnabled : true
    );

    setTopMenuItems(filteredTopMenuItems);
    setBottomMenuItems(props.bottomMenuItems);
  }, [props.topMenuItems, props.bottomMenuItems, isDIDFlowEnabled]);

  function getIconSource(item: string) {
    if (item === "Dashboard") return dashboard;
    else if (item === "Sip Trunk") return siptrunk;
    else if (item === "Numbers") return numbers;
    else if (item === "Pricing") return pricing;
    else if (item === "settings") return setting;
    else if (item === "Report") return report;
    else if (item === "Recharge") return recharge;
    else if (item === "Cloud Telephony") return cloudTelephony;
  }

  const toggleMenuBar = () => {
    props.onToggle(!isExpanded);
    setIsExpanded(!isExpanded);
    setIsMenuBarExpanded(!isExpanded);
  };

  const handleItemClick = (item: MenuItem) => {
    navigate(item.route);
  };

  const handleMouseEnter = (item: MenuItem) => {
    setTopMenuItems(
      topMenuItems.map((menuItem) => {
        if (menuItem.name === item.name) menuItem.isHover = true;
        return menuItem;
      })
    );

    setBottomMenuItems(
      bottomMenuItems.map((menuItem) => {
        if (menuItem.name === item.name) menuItem.isHover = true;
        return menuItem;
      })
    );
  };

  const handleMouseLeave = (item: MenuItem) => {
    setTopMenuItems(
      topMenuItems.map((menuItem) => {
        if (menuItem.name === item.name) menuItem.isHover = false;
        return menuItem;
      })
    );

    setBottomMenuItems(
      bottomMenuItems.map((menuItem) => {
        if (menuItem.name === item.name) menuItem.isHover = false;
        return menuItem;
      })
    );
  };

  function renderSearchBox() {
    return (
      <div className={styles.searchBoxContainer}>
        <div className={styles.searchBar}>
          <input
            type="search"
            placeholder="Search"
            value={searchItem}
            id="search"
            name="searchbar"
            className={classNames(styles.searchInput,
              isExpanded ? styles.searchInputExpanded : styles.searchInputClosed
            )}
            data-testid="searchbox"
            onChange={() => {}}
          />
          <img src={search} alt="Search-icon" className={styles.searchIcon} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.menubar} data-testid="menu-bar">
      <div className={styles.collapsedSidebar}>
        <div
          className={styles.logoContainer}
          onClick={() => navigate("/")}
        >
          <img src={pegionlogo} alt="logo" className={styles.logoImage} />
        </div>
        <div
          className="sidebar-item-toggle"
          onClick={toggleMenuBar}
          data-testid="toggle-button"
          aria-label="Toggle menu"
        >
          <img
            src={isExpanded ? leftarrow : rightarrow}
            alt="toggle-button"
            className={classNames(
              styles.toggleIcon,
              isExpanded ? styles.toggleIconInwards : styles.toggleIconOutWards
            )}
            data-testid={"toggle-button-icon"}
          />
        </div>
        <div
          className={styles.topMenuIconContainer}
          data-testid="top-menu-item-container"
        >
          {topMenuItems.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              className={classNames(
                styles.iconContainer,
                item.isHover
                  ? styles.iconContainerHoverEffect
                  : styles.iconContainerUnHoverEffect
              )}
              onClick={() => handleItemClick(item)}
              data-testid="top-menu-item"
            >
              <img
                src={getIconSource(item.name)}
                alt={item.name + "-icon"}
                className={classNames(styles.menuIcon,
                  selectedItem === item ? styles.selectedIcon : null
                )}
                data-testid="sidebar-top-icon"
              />
            </div>
          ))}
        </div>
        <div
          className={styles.bottomMenuIconContainer}
          data-testid="bottom-menu-item-container"
        >
          {bottomMenuItems.map((item, index) => (
            <div
              key={index}
              className={classNames(
                styles.iconContainer,
                item.isHover
                  ? styles.iconContainerHoverEffect
                  : styles.iconContainerUnHoverEffect
              )}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              data-testid="bottom-menu-item"
            >
              {item.isHover && <div className={styles.iconHoverEffect} />}
              <div className={styles.menuIconImageContainer}>
                <img
                  src={getIconSource(item.name)}
                  alt={item.name + "-icon"}
                  className={styles.menuIcon}
                  data-testid="sidebar-bottom-icon"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          styles.sidebarExpandable,
          !isExpanded ? styles.sidebarExpandableHidden : styles.sideBarExpandableOpen
        )}
        data-testid="expanded-sidebar"
      >
        {renderSearchBox()}
        <div className={styles.topMenuItemContainer}>
          {topMenuItems.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              className={classNames(
                styles.menuTextContainer,
                item.isHover
                  ? styles.menuTextHoverEffect
                  : styles.menuTextUnHoverEffect
              )}
              onClick={() => handleItemClick(item)}
              data-testid={"top-menu-text"}
            >
              <span>{item.description}</span>
            </div>
          ))}
        </div>

        <div className={styles.bottomMenuItemContainer}>
          {bottomMenuItems.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              className={classNames(
                styles.menuTextContainer,
                item.isHover
                  ? styles.menuTextHoverEffect
                  : styles.menuTextUnHoverEffect
              )}
              data-testid={"bottom-menu-text"}
            >
              <span>{item.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
