import React, {useContext, useEffect, useRef, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {OTPContext} from "../../../contextApi/OTPContext/OTPContext";
import {registerUser} from "../../../api/endpoint";
import {useQuery} from "react-query";
import {notyf} from "../../../notyf";
import {isOTPRequired} from "../../../requester";
import styles from "./SignUp.module.css";
import countriesCode from "../../../countryCode.json";
import {
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOffOutlined,
  VisibilityOutlined
} from "@mui/icons-material";
import Dialog, {Size} from "../../../components/business/Dialog/Dialog";
import SkyButton, {ButtonSize} from "../../../components/base/SkyButton/SkyButton";
import welcomeImage from "../../../icons/welcome.png";
import classNames from "classnames";
import SkyTooltip from "../../../components/base/SkyTooltip/SkyTooltip";
import DialCodeSelector from "../../../components/business/DialCodeSelector/DialCodeSelector";
import loaderClip from "../../../images/transparent loader.svg";

type Country = {
  id: string;
  name: string;
  dialCode: string;
  prefix: string;
  flag?: JSX.Element;
};

type FormField = {
  isRequired: boolean,
  isValid: boolean,
  data: string,
  errorMessage: string
}

type FormFields = {
  firstName: FormField;
  lastName: FormField;
  email: FormField;
  password: FormField;
  confirmPassword: FormField;
  contactNumber: FormField;
}

const SignUp = () => {
  const navigate = useNavigate();
  const nameRegex = /^[A-Za-z ]+$/;
  const emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-])[A-Za-z\d!@#$%^&*._-]{8,15}$/;
  const contactNumberRegex = /^\d+$/;

  const nameMaxLengthErrorMessage = "Must be less than 15 chars!";
  const nameSpecialCharacterErrorMessage = "Must contain only letters!";
  const validateField = (value, regex, minLength, maxLength) =>
    regex.test(value) && value.length >= minLength && value.length <= maxLength;
  const initialFormFieldData = {
    firstName: {isRequired: true, data: "", isValid: false, errorMessage: ""},
    lastName: {isRequired: true, data: "", isValid: false, errorMessage: ""},
    email: {isRequired: true, data: "", isValid: false, errorMessage: ""},
    password: {isRequired: true, data: "", isValid: false, errorMessage: ""},
    confirmPassword: {isRequired: true, data: "", isValid: false, errorMessage: ""},
    contactNumber: {isRequired: true, data: "", isValid: false, errorMessage: ""}
  };
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [formFields, setFormFields]
    = useState<FormFields>(initialFormFieldData);

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [isDialCodeSelectorOpen, setIsDialCodeSelectorOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
  } = useContext(OTPContext);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDialCodeSelectorOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      handleRegistrationSuccess();
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  const isFormValid = formFields.firstName.isValid
    && formFields.lastName.isValid
    && formFields.email.isValid
    && formFields.password.isValid
    && formFields.confirmPassword.isValid
    && formFields.contactNumber.isValid

  const {isFetching: isProcessing, data: registrationResult, refetch: register} = useQuery(
    ["registerUser"],
    () => registerUser({
      firstName: formFields.firstName.data,
      lastName: formFields.lastName.data,
      username: formFields.email.data,
      password: formFields.password.data,
      confirmPassword: formFields.confirmPassword.data,
      contactNumber: (selectedCountry?.dialCode ?? "+1") + ":" + formFields.contactNumber.data,
      address: "",
      state: "",
      zipCode: ""
    }),
    {
      enabled: false,
      onSuccess: data => {
        if (data.userId) handleRegistrationSuccess();
        else notyf.error("Something went wrong. Please try again later.");
      },
      onError: (error: any) => {
        if (isOTPRequired(error)) {
          setOTPRequired(true);
          setErrorResponse(error);
        } else if (error?.response?.status === 409) {
          setFormFields({
            ...formFields,
            email: {...formFields.email, isValid: false, errorMessage: error?.response?.data?.error}
          });
        } else {
          notyf.error("Something went wrong. Please try again later.");
        }
      }
    }
  );

  const handleRegistrationSuccess = () => {
    setIsSuccessDialogOpen(true);
  }

  const handleDialCodeSelection = (country: Country) => {
    setSelectedCountry(country);
    setIsDialCodeSelectorOpen(false);
  };

  return (
    <>
      {isProcessing && (
        <div className={styles.loaderContainer}>
          <img src={loaderClip} alt="loader" className={styles.loader}/>
        </div>
      )}
      {isSuccessDialogOpen &&
         <Dialog
            size={Size.SM}
            renderer={() => (
              <div className={styles.successMessageContainer}>
                <img
                  src={welcomeImage}
                  alt="Success icon"
                  className={styles.successImage}
                  data-testid={"success-icon"}
                />
                <p className={styles.nameContainer}>Hi {formFields.firstName.data}</p>
                <p>Thanks for registering an account with us!</p>
                <p className={styles.dialogueMessage}>
                  You can login now from login screen.
                </p>
                <div className={styles.dialogButtonContainer}>
                  <SkyButton
                    text="OK"
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      setIsSuccessDialogOpen(false);
                      navigate("/");
                    }}
                    disabled={false}
                    testId={"dialog-confirm-button"}
                  />
                </div>
              </div>
            )}
         />}
      {!isProcessing && (
        <div className={styles.outerFieldContainer} data-testid={"sign-up-form"}>
          <div className={styles.text}>Registration</div>
          <div className={styles.fieldsRow}>
            <div className={styles.fieldContainer}>
              <label htmlFor="firstName" className={styles.formLabel}>
                First Name
                <span className={styles.required}>*</span>
              </label>
              <input
                className={classNames(
                  styles.formInput,
                  !formFields.firstName.isValid && formFields.firstName.data && styles.errorFormInput
                )}
                type="text"
                name="firstName"
                data-testid={"first-name"}
                value={formFields.firstName.data}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    firstName: {...formFields.firstName, data: e.target.value, isValid: true}
                  });
                }}
                onBlur={(e) => {
                  const fieldValue = e.target.value;
                  if (!nameRegex.test(fieldValue))
                    setFormFields({
                      ...formFields,
                      firstName: {
                        ...formFields.firstName,
                        isValid: false,
                        errorMessage: nameSpecialCharacterErrorMessage
                      }
                    });
                  else if (fieldValue.length > 15)
                    setFormFields({
                      ...formFields,
                      firstName: {
                        ...formFields.firstName,
                        isValid: false,
                        errorMessage: nameMaxLengthErrorMessage
                      }
                    });
                }}
              />
              {!formFields.firstName.isValid
                && (<span className={styles.errorMessage}>{formFields.firstName.errorMessage}</span>)}
            </div>

            <div className={styles.fieldContainer}>
              <label htmlFor="lastName" className={styles.formLabel}>
                Last Name
                <span className={styles.required}>*</span>
              </label>
              <input
                className={classNames(
                  styles.formInput,
                  !formFields.lastName.isValid && formFields.lastName.data && styles.errorFormInput
                )}
                type="text"
                name="lastName"
                data-testid={"last-name"}
                value={formFields.lastName.data}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    lastName: {...formFields.lastName, data: e.target.value, isValid: true}
                  });
                }}
                onBlur={(e) => {
                  const fieldValue = (e.target as HTMLInputElement).value;
                  if (!nameRegex.test(fieldValue))
                    setFormFields({
                      ...formFields,
                      lastName: {
                        ...formFields.lastName,
                        isValid: false,
                        errorMessage: nameSpecialCharacterErrorMessage
                      }
                    });
                  else if (fieldValue.length > 15)
                    setFormFields({
                      ...formFields,
                      lastName: {
                        ...formFields.lastName,
                        isValid: false,
                        errorMessage: nameMaxLengthErrorMessage
                      }
                    });
                }}
              />
              {!formFields.lastName.isValid
                && (<span className={styles.errorMessage}>{formFields.lastName.errorMessage}</span>)}
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <label htmlFor="email" className={styles.formLabel}>
              Email
              <span className={styles.required}>*</span>
            </label>
            <input
              className={classNames(
                styles.formInput,
                !formFields.email.isValid && formFields.email.data && styles.errorFormInput
              )}
              type="text"
              name="email"
              data-testid={"email"}
              value={formFields.email.data}
              onChange={(e) => {
                setFormFields({
                  ...formFields,
                  email: {...formFields.email, data: e.target.value, isValid: true}
                });
              }}
              onBlur={(e) =>
                !validateField(e.target.value, emailRegex, 1, Infinity)
                && setFormFields({
                  ...formFields,
                  email: {...formFields.email, isValid: false, errorMessage: "Invalid Email!"}
                })
              }
            />
            {!formFields.email.isValid
              && (<span
                className={styles.errorMessage}
                data-testid={"email-validation-error"}>
                {formFields.email.errorMessage}
              </span>)}
          </div>

          <div className={styles.fieldsRow}>
            <div className={styles.fieldContainer}>
              <label htmlFor="password" className={styles.formLabel}>
                Password
                <span className={styles.required}>*</span>
              </label>
              <div className={styles.passwordField}>
                <input
                  className={classNames(styles.formInput,
                    styles.passwordInputField,
                    !formFields.password.isValid && formFields.password.data && styles.errorFormInput
                  )}
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  data-testid={"password"}
                  value={formFields.password.data}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      password: {...formFields.password, data: e.target.value, isValid: true}
                    });
                  }}
                  onBlur={e => {
                    let updatedFields = {...formFields};
                    if (!validateField(e.target.value, passwordRegex, 8, 15)) {
                      updatedFields.password = {
                        ...updatedFields.password,
                        isValid: false,
                      };
                      setShowTooltip(true);
                    }

                    if (formFields.confirmPassword.data !== "" && formFields.confirmPassword.data !== e.target.value)
                      updatedFields.confirmPassword = {
                        ...updatedFields.confirmPassword,
                        isValid: false,
                        errorMessage: "Password does not match!"
                      };

                    setFormFields(updatedFields);
                  }}
                  onFocus={() => setShowTooltip(false)}
                />
                <span className={styles.visibilityIcon}
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      data-testid={"toggle-password-visibility-icon"}
                >
                      {isPasswordVisible ?
                        <VisibilityOutlined data-testid={"view-password-icon"}/> :
                        <VisibilityOffOutlined data-testid={"hide-password-icon"}/>}
                </span>

                <SkyTooltip
                  tooltipText={"Password must contain between 8 and 15 characters, including UPPERCASE, lowercase, numbers and special characters.(ex. A1b2c3@e)"}
                  isOpen={showTooltip}
                >
                  <InfoOutlined/>
                </SkyTooltip>
              </div>
            </div>

            <div className={styles.fieldContainer}>
              <label htmlFor="confirmPassword" className={styles.formLabel}>
                Confirm Password
                <span className={styles.required}>*</span>
              </label>
              <input
                className={classNames(
                  styles.formInput,
                  !formFields.confirmPassword.isValid && formFields.confirmPassword.data && styles.errorFormInput
                )}
                type="password"
                name="confirmPassword"
                data-testid={"confirm-password"}
                value={formFields.confirmPassword.data}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    confirmPassword: {...formFields.confirmPassword, data: e.target.value, isValid: true}
                  });
                }}
                onBlur={e =>
                  formFields.password.data !== e.target.value
                  && setFormFields({
                    ...formFields,
                    confirmPassword: {
                      ...formFields.confirmPassword,
                      isValid: false,
                      errorMessage: "Password does not match!"
                    }
                  })
                }
              />
              {!formFields.confirmPassword.isValid
                && (<span className={styles.errorMessage}>{formFields.confirmPassword.errorMessage}</span>)}
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <label htmlFor="contactNumber" className={styles.formLabel}>
              Contact Number
              <span className={styles.required}>*</span>
            </label>
            <div className={styles.contactNumberFieldContainer}>
              <div
                onClick={() => setIsDialCodeSelectorOpen(!isDialCodeSelectorOpen)}
                data-testid={"dial-code"}
              >
                <span>{selectedCountry?.dialCode ?? "+1"}</span>
                {isDialCodeSelectorOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
              </div>
              <input
                className={classNames(styles.formInput,
                  styles.contactNumberInput,
                  !formFields.contactNumber.isValid && formFields.contactNumber.data && styles.errorFormInput
                )}
                type="number"
                data-testid={"contact-number"}
                value={formFields.contactNumber.data}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    contactNumber: {
                      ...formFields.contactNumber,
                      data: e.target.value, isValid: true
                    }
                  });
                }}
                onBlur={e =>
                  !validateField(e.target.value, contactNumberRegex, 1, 15)
                  && setFormFields({
                    ...formFields,
                    contactNumber: {
                      ...formFields.contactNumber,
                      isValid: false,
                      errorMessage: "Invalid Contact Number!"
                    }
                  })
                }
                name="contactNumber"
              />
            </div>
            {!formFields.contactNumber.isValid
              && (<span className={styles.errorMessage}>{formFields.contactNumber.errorMessage}</span>)}
          </div>

          {isDialCodeSelectorOpen && (
            <div
              ref={dropdownRef}
              className={styles.dialCodeSelector}
            >
              <DialCodeSelector
                countries={countriesCode.map((country) => ({
                  id: country.code,
                  name: country.name,
                  code: country.code,
                  dialCode: country.dialCode
                }))}
                backgroundColor={"var(--white)"}
                fontColor={"var(--gray)"}
                onSelect={(country) =>
                  handleDialCodeSelection({
                    id: country.id,
                    name: country.name,
                    dialCode: country.dialCode,
                    prefix: country.code,
                    flag: country.flag
                  })
                }
              />
            </div>
          )}

          <div className={styles.buttonContainer}>
            <button
              onClick={() => register()}
              className={classNames(styles.button, !isFormValid ? styles.disabled : "")}
              data-testid="sign-up-button"
              disabled={!isFormValid}
            >
              Register
            </button>
          </div>
        </div>
      )}
    </>
  )
}
export default SignUp
