import React, {FC, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styles from "./RechargeConfirmationScreen.module.css";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import RechargeSuccess from "./RechargeSuccess/RechargeSuccess";
import RechargeFailure from "./RechargeFailure/RechargeFailure";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import {completePayment} from "../../api/endpoint";
import {notyf} from "../../notyf";
import RechargePending from "./RechargePending/RechargePending";
import {PaymentType} from "../../domains/PaymentType";

const RechargeConfirmationScreen: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("USD");
  const [date, setDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [errorCode, setErrorCode] = useState<string>("");
  const [isMailSent, setIsMailSent] = useState<boolean>(false);
  const [isBalanceUpdated, setIsBalanceUpdated] = useState<boolean>(false);
  const location = useLocation();
  const { accessToken } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const intentId = searchParams.get("id");
  const paymentTypeQuery = searchParams.get("paymentType");
  const [paymentType, setPaymentType] = useState<PaymentType>(
    paymentTypeQuery ? (paymentTypeQuery as PaymentType) : PaymentType.RECHARGE
  );

  useEffect(() => {
    if (!intentId) return;
    completePayment(accessToken ? accessToken : "", intentId)
      .then((response) => {
        try {
          setIsSuccess(response.paymentDetails.status == "SUCCEEDED");
          setIsPending(response.paymentDetails.status == "PENDING")
          setIsFailed(response.paymentDetails.status !== "SUCCEEDED" && response.paymentDetails.status !== "PENDING");
          setAmount(response.paymentDetails.amount);
          setTransactionId(response.paymentDetails.intentId);
          setCurrency(response.paymentDetails.currency);
          setDate(response.paymentDetails.date);
          setStatus(response.paymentDetails.status);
          setIsBalanceUpdated(response.isBalanceUpdated);
          setIsMailSent(response.isMailSent);
          setErrorCode(response.errorCode);
          setPaymentType(response.paymentDetails.paymentType)
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsSuccess(false);
        notyf.error("Unable to get payment confirmation.");
      });
  }, [intentId]);

  const getLoadingMessage = () => {
    return paymentType === PaymentType.RECHARGE
      ? "Your recharge is under process. Please don't click refresh or back button."
      : "Your invoice payment is under process. Please don't click refresh or back button.";
  };

  return (
    <>
      {isLoading && (
        <div className={styles.waitingContainer}>
          <div>
            <DotLoader />
          </div>
          <div>
            <h3>
              {getLoadingMessage()}
            </h3>
          </div>
        </div>
      )}
      {!isLoading && isSuccess && (
        <RechargeSuccess
          amount={amount}
          currency={currency}
          transactionId={transactionId}
          date={date}
          isMailSent={isMailSent}
          status={status}
          paymentType={paymentType}
        />
      )}
      {!isLoading && isPending && (
        <RechargePending
          paymentType={paymentType}
        />
      )}
      {!isLoading && isFailed  && (
        <RechargeFailure
          isBalanceUpdated={isBalanceUpdated}
          gatewayStatus={status}
          errorCode={errorCode}
          paymentType={paymentType}/>
      )}
    </>
  );
};

export default RechargeConfirmationScreen;
