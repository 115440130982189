import React, {useEffect, useRef, useState} from "react";
import styles from "./TermsAndConditionsDialog.module.css";
import parse from "html-react-parser";
import {TermsAndConditions} from "../../../models/response/FetchTermsAndConditions";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";
import DotLoader from "../DotLoader/DotLoader";
import classNames from "classnames";

interface TermsAndConditionsDialog {
  termsAndConditions: TermsAndConditions[];
  onConfirm: () => Promise<void>;
}

const TermsAndConditionsDialog: React.FC<TermsAndConditionsDialog> = ({termsAndConditions, onConfirm}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      setHasScrolledToBottom(scrollHeight <= clientHeight);
    }
  }, []);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const isAtBottom = scrollHeight - (scrollTop + clientHeight) < 10;

      if (isAtBottom && !hasScrolledToBottom) {
        setHasScrolledToBottom(true);
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleAcceptClick = () => {
    setLoading(true);
    onConfirm().finally(() => setLoading(false));
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        ref={contentRef}
        onScroll={handleScroll}
        data-testid="terms-content"
      >
        {termsAndConditions.map((term, index) => (
          <div key={term.id} className={styles.item}>
            <div className={styles.itemContainer}>
              <h4 className={styles.itemTitle}>{term.title}</h4>
              <p className={styles.itemText}>{parse(term.content)}</p>
            </div>
            {index < termsAndConditions.length - 1 && <hr className={styles.divider}/>}
          </div>
        ))}
      </div>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="acceptCheckbox"
          checked={isChecked}
          disabled={!hasScrolledToBottom}
          onChange={handleCheckboxChange}
        />
        <label
          htmlFor="acceptCheckbox"
          className={classNames(
          styles.checkboxLabel,
          hasScrolledToBottom ? "" : styles.disabledCheckboxLabel)}
        >
          I have read and accept the Terms and Conditions
        </label>
      </div>
      <div className={styles.actions}>
        {loading ? (
          <DotLoader/>
        ) : (
          <SkyButton
            size={ButtonSize.SMALL}
            text="Accept"
            onClick={handleAcceptClick}
            disabled={!isChecked || loading}
          />
        )}
      </div>
    </div>
  );
};

export default TermsAndConditionsDialog;
