import React, { FC } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import styles from "./CLIDetailsContainer.module.css";
import { CLIDetails } from "../../../models/response/UserTrunkListResponse";

type Props = {
  cliDetails: CLIDetails[];
};

const CLIDetailsContainer: FC<Props> = (props) => {
  if (props.cliDetails.length === 0) {
    return <span>No CLI details available</span>;
  }

  const cliTooltipRenderer = () => (
    <div>
      <strong>CLI Details:</strong>
      <table className={styles.cliDetailsTable}>
        <thead>
        <tr>
          <th>Country Code</th>
          <th>CLI</th>
        </tr>
        </thead>
        <tbody>
        {props.cliDetails.map((cliDetail) => (
          <tr key={cliDetail.cli}>
            <td>{cliDetail.countryCode}</td>
            <td>{cliDetail.cli}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <SkyTooltip
      tooltipRenderer={cliTooltipRenderer}
    >
      <span className={styles.cliDetailsContainer}>
        {props.cliDetails.length}
        <ArrowBackIosIcon
          data-testid="arrow-icon"
          className={styles.arrowDownIcon}
        />
      </span>
    </SkyTooltip>
  );
};

export default CLIDetailsContainer;
