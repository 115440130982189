import React, { useState, useContext, useEffect } from "react";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import styles from "./AddExtension.module.css";
import errorIcon from "../../../../icons/error-emoji.png";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import { OTPContext } from "../../../../contextApi/OTPContext/OTPContext";
import { createExtension } from "../../../../api/endpoint";
import { notyf } from "../../../../notyf";
import DotLoader from "../../../business/DotLoader/DotLoader";
import { isOTPRequired } from "../../../../requester";
import classNames from "classnames";
import { UserDetailsContext } from "../../../../contextApi/UserDetailsContext/UserDetailsContext";

type AddExtensionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const AddExtensionDialog: React.FC<AddExtensionDialogProps> = ({
                                                                 isOpen,
                                                                 onClose,
                                                                 onSuccess
                                                               }) => {
  const [newExtension, setNewExtension] = useState("");
  const [extensionError, setExtensionError] = useState("");
  const [isExtensionAdded, setIsExtensionAdded] = useState(false);
  const [isCreatingExtension, setIsCreatingExtension] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const { user } = useContext(UserDetailsContext);

  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess
  } = useContext(OTPContext);

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      handleExtensionCreationSuccess();
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  const handleExtensionCreationSuccess = () => {
    setIsExtensionAdded(true);
    onSuccess();
    notyf.success("Extension created successfully");
    setIsCreatingExtension(false);
  };

  const handleExtensionCreationError = (error: any) => {
    setIsCreatingExtension(false);
    if (isOTPRequired(error)) {
      setOTPRequired(true);
      setErrorResponse(error);
    } else {
      const errorMessage = error?.response?.status === 409
        ? "Extension already exists to your account"
        : "Unable to add extension. Please contact support@skytelservices.com for assistance.";

      setExtensionError(errorMessage);
    }
  };

  const validateExtension = (value: string) => {
    if (value.length !== 5) {
      setExtensionError("Extension must be exactly 5 characters");
      return false;
    }
    return true;
  };

  const handleAddExtension = async () => {
    if (!accessToken) {
      return;
    }
    if (!validateExtension(newExtension)) {
      return;
    }

    setIsCreatingExtension(true);
    try {
      await createExtension(accessToken, {
        username: newExtension
      });
      handleExtensionCreationSuccess();
    } catch (error) {
      handleExtensionCreationError(error);
    }
  };

  const handleClose = () => {
    onClose();
    setNewExtension("");
    setExtensionError("");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9]{0,5}$/.test(value)) {
      setNewExtension(value);
      setExtensionError("");
    }
  };

  if (!isOpen) return null;

  if (isExtensionAdded) {
    return (
      <Dialog
        size={Size.SM}
        header="Add New Extension"
        renderer={() => (
          <div className={classNames(styles.addExtensionOuterContainer, styles.confirmationContainer)}>
            <span className={styles.confirmationHeader}>
              Extension Created Successfully
            </span>
            <span className={styles.confirmationText} data-testid="confirmation-text">
              Extension {user?.account}{newExtension} has been created successfully.
            </span>
            <SkyButton
              onClick={handleClose}
              size={ButtonSize.SMALL}
              testId="ok-button"
              text="Ok"
            />
          </div>
        )}
      />
    );
  }

  return (
    <Dialog
      size={Size.SM}
      header="Create New Extension"
      renderer={() => (
        <div className={styles.addExtensionOuterContainer} data-testid="add-extension-dialog">
          <div className={styles.textLabel}>Extension Number</div>
          <div className={styles.helperText}>
            The extension will include your account ID as a prefix.
          </div>
          <div className={styles.combinedInputContainer}>
            <input
              className={classNames(
                styles.roundControl,
                styles.vosAccountInput,
                extensionError && styles.errorInput
              )}
              type="text"
              value={user?.account}
              disabled
            />
            <input
              type="text"
              value={newExtension}
              onChange={handleInputChange}
              placeholder="Enter 5-char extension"
              className={styles.extensionInputBox}
              maxLength={5}
              data-testid="extension-input"
            />
          </div>
          {extensionError && (
            <div
              className={styles.errorMessageContainer}
              data-testid="error-message"
            >
              <img
                src={errorIcon}
                alt="error icon"
                className={styles.errorIcon}
              />
              {extensionError}
            </div>
          )}
          <div className={styles.addExtensionButtonContainer}>
            {isCreatingExtension ? (
              <div className={styles.loaderContainer}>
                <DotLoader />
              </div>
            ) : (
              <>
                <SkyButton
                  text="Add"
                  onClick={handleAddExtension}
                  size={ButtonSize.SMALL}
                  disabled={!newExtension || !!extensionError}
                  testId="add-extension-button"
                />
                <SkyButton
                  text="Cancel"
                  onClick={handleClose}
                  size={ButtonSize.SMALL}
                  testId="cancel-extension-button"
                />
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default AddExtensionDialog;