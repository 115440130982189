import React from 'react'
import {useNavigate} from "react-router-dom";
import styles from "./SignInScreen.module.css";
import logo from "../../images/logo.png";
import Login from "./Login/Login";

const SignInScreen = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.signInContainer}>
      <div className={styles.headerContainer}>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
        />
        <div className={styles.loginFooter}>
          <div className={styles.loginFooterText}>Lets Get Started!</div>
          <div className={styles.loginFooterDescription}>Welcome to a new journey</div>
        </div>
      </div>
      <div className={styles.loginWrapperContainer}>
        <Login/>
      </div>
      <div className={styles.signFooter}>
        <div className={styles.registerPromptText}>
          Don't have an account yet?
        </div>
        <button
          onClick={() => navigate("/register")}
          className={styles.registerText}
        >
          Register Now
        </button>
      </div>
    </div>
  )
}
export default SignInScreen
